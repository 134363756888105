import React from 'react';

import { CartPanda as CartPandaIcon } from '@profitfy/pakkun-icons';
import { X as CloseIcon } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { ECartPandaType } from '@domain/enums/common/integrations/providers/ECartPanda';
import { ICartPandaProps } from '@domain/interfaces/dashboard/Automations/Canvas/Integrations/cartpanda';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useAutomationsTemplate } from '@helpers/hooks/pages/dashboard/automations/useAutomationsTemplate';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { cartPandaSchema } from '@helpers/validators/dashboard/integrationCenter/cartPanda';

import cartPandaService from '@services/pages/dashboard/integrationCenter/storeProvider/cartPanda/cartPandaAuth';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const CartPanda: React.FC<ICartPandaProps> = ({ toggle }) => {
  const { colors } = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();
  const { generateInitialNodesAndEdges, templateType, templateQuery } = useAutomationsTemplate();
  const {
    selectedTriggerForMissingCredential,
    setShowSideModal,
    handleSelectWorkflowTriggerType,
  } = useAutomation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(cartPandaSchema) });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [domainValue, setDomainValue] = React.useState<string>('');
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');

  const domainRegister = register('domain');
  const accessTokenRegister = register('access_token');

  const onDomainValueChange = React.useCallback(
    event => {
      domainRegister.onChange(event);

      setDomainValue(event.target.value);
    },
    [domainRegister],
  );

  const onAccessTokenValueChange = React.useCallback(
    event => {
      accessTokenRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenRegister],
  );

  const sanitizeUrl = React.useCallback(url => {
    const replacedUrl = url.replace('https://', '').replace('wwww.', '');

    const parsedUrl = replacedUrl.split('.mycartpanda.com')[0];

    return parsedUrl;
  }, []);

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      if (!selectedTriggerForMissingCredential && !templateQuery) {
        toast.error(
          'Ocorreu um erro ao tentar adicionar a credencial da CartPanda, tente novamente mais tarde!',
        );

        return;
      }

      const sanitizedUrl = sanitizeUrl(data.domain);

      try {
        const types: Array<any> = [];

        if (templateQuery) {
          types.push(templateType);
        } else {
          types.push((selectedTriggerForMissingCredential as unknown) as ECartPandaType);
        }

        await cartPandaService.createCredential({
          storeAliasId,
          data: {
            access_token: data.access_token,
            domain: sanitizedUrl,
            types,
          },
        });

        mutateStore();

        if (templateQuery) {
          generateInitialNodesAndEdges();

          setShowSideModal(false);
          handleSelectWorkflowTriggerType(templateType as ETriggers);
        }

        setIsSubmittingForm(false);
        toggle();
      } catch (err: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(err));
      }
    },
    [
      storeAliasId,
      toast,
      sanitizeUrl,
      mutateStore,
      toggle,
      selectedTriggerForMissingCredential,
      handleSelectWorkflowTriggerType,
      setShowSideModal,
      templateQuery,
      templateType,
      generateInitialNodesAndEdges,
    ],
  );

  const hasAllInputsValue = domainValue && accessTokenValue;

  return (
    <>
      <S.Row>
        <S.GroupTitle>
          <CartPandaIcon size={22} />
          <S.Title>Integração com a CartPanda</S.Title>
        </S.GroupTitle>
        <S.CloseButton onClick={toggle}>
          <CloseIcon size={20} color={colors.gray[3]} />
        </S.CloseButton>
      </S.Row>

      <S.Row>
        <S.Tutorial>
          Para saber como pegar essas informações da CartPanda veja o{' '}
          <S.Link
            href="https://ajuda.profitfy.me/pt-br/articles/6476831-como-integrar-com-a-loja-cartpanda"
            target="_blank"
          >
            nosso artigo
          </S.Link>{' '}
          .
        </S.Tutorial>
      </S.Row>

      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="cart-panda-store-url">URL da Loja</S.Label>

            <S.InputWrapper isError={errors.domain} value={domainValue}>
              <S.TextFieldWithAppendContent
                {...domainRegister}
                type="text"
                id="cart-panda-store-url"
                onChange={onDomainValueChange}
              />
              <S.AppendContent>.mycartpanda.com</S.AppendContent>
            </S.InputWrapper>
            {errors.domain && <Text isErrorFeedback>{errors.domain.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="access-token">Access Token</S.Label>
            <S.TextField
              {...accessTokenRegister}
              type="text"
              id="access-token"
              onChange={onAccessTokenValueChange}
              value={accessTokenValue}
              isError={Boolean(errors.access_token)}
            />
            {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
          </S.InputGroup>

          <S.ButtonWrapper>
            <S.ButtonCancel type="reset" onClick={toggle}>
              Cancelar
            </S.ButtonCancel>

            <S.ButtonSave
              isLoading={isSubmittingForm}
              disabled={isSubmittingForm || !hasAllInputsValue}
            >
              Salvar credencial
            </S.ButtonSave>
          </S.ButtonWrapper>
        </S.Form>
      </S.FormWrapper>
    </>
  );
};

export default CartPanda;
