import React from 'react';

import { Hotmart as HotmartIcon, Copy } from '@profitfy/pakkun-icons';
import { X as CloseIcon } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IZoutiProps } from '@domain/interfaces/dashboard/Automations/Canvas/Integrations/zouti';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useAutomationsTemplate } from '@helpers/hooks/pages/dashboard/automations/useAutomationsTemplate';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { hotmartSchema } from '@helpers/validators/dashboard/integrationCenter/infoProduct/hotmart';

import hotmartService from '@services/pages/dashboard/integrationCenter/infoProduct/platforms/hotmart';

import Text from '@components/common/core/DataDisplay/Text';

import { ETextWeight } from '@domain/enums/components/EText';
import { EPlatform } from '@domain/enums/common/EPlatform';
import * as S from './styles';

const webhookURL = 'https://dosu-api.profitfy.me/prod/api/v1/webhooks/hotmart/events';

const Hotmart: React.FC<IZoutiProps> = ({ toggle }) => {
  const theme = useTheme();

  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore, storeIntegrations } = useStoreConfig();
  const { generateInitialNodesAndEdges, templateType, templateQuery } = useAutomationsTemplate();
  const {
    selectedTriggerForMissingCredential,
    setShowSideModal,
    handleSelectWorkflowTriggerType,
  } = useAutomation();

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(hotmartSchema) });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);

  const clientIdInputRegister = register('client_id');
  const clientSecretInputRegister = register('client_secret');
  const basicInputRegister = register('basic');
  const hottokInputRegister = register('provider_id');

  const [clientId, clientSecret, basic, providerId] = getValues([
    'client_id',
    'client_secret',
    'basic',
    'provider_id',
  ]);

  watch();

  const hasHotmartIntegration = Boolean(
    storeIntegrations?.store_front.find(
      integration => integration.name === EPlatform.HOTMART && integration.is_active,
    ),
  );

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      if (!selectedTriggerForMissingCredential && !templateQuery) {
        toast.error(
          'Ocorreu um erro ao tentar adicionar a credencial da Hotmart, tente novamente mais tarde!',
        );

        return;
      }

      try {
        const types: Array<any> = [];

        if (templateQuery) {
          types.push(templateType);
        } else {
          types.push(selectedTriggerForMissingCredential);
        }

        if (hasHotmartIntegration) {
          const { data } = await hotmartService.getCredential({ storeAliasId });
          const { hotmart_credential } = data;

          await hotmartService.updateCredential({
            storeAliasId,
            credentialAliasId: hotmart_credential.alias_id,
            data: {
              ...formData,
              types: [...hotmart_credential.types, ...types],
            },
          });
        } else {
          await hotmartService.createCredential({
            storeAliasId,
            data: {
              ...formData,
              types,
            },
          });
        }

        if (templateQuery) {
          generateInitialNodesAndEdges();

          setShowSideModal(false);
          handleSelectWorkflowTriggerType(templateType as ETriggers);
        }

        setIsSubmittingForm(false);
        mutateStore();
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [
      toast,
      storeAliasId,
      mutateStore,
      hasHotmartIntegration,
      selectedTriggerForMissingCredential,
      handleSelectWorkflowTriggerType,
      setShowSideModal,
      templateQuery,
      templateType,
      generateInitialNodesAndEdges,
    ],
  );

  const hasAllInputsValue = clientId && clientSecret && basic && providerId;

  return (
    <>
      <S.Row>
        <S.GroupTitle>
          <HotmartIcon size={22} />

          <S.Title>Integração com Hotmart</S.Title>
        </S.GroupTitle>

        <S.CloseButton onClick={toggle}>
          <CloseIcon size={20} color={theme.colors.gray[3]} />
        </S.CloseButton>
      </S.Row>

      <S.Row>
        <S.Tutorial>1 - Copie a URL no campo abaixo.</S.Tutorial>
      </S.Row>

      <S.Row>
        <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
          <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
          <S.IconWrapper>
            <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
          </S.IconWrapper>
        </S.WebhookURLWrapper>
      </S.Row>

      <S.Row>
        <S.Tutorial>
          Para saber como pegar as informações da Hotmart veja o{' '}
          <S.Link
            href="https://ajuda.profitfy.me/pt-br/articles/6803844-como-integrar-com-a-hotmart"
            target="_blank"
          >
            nosso artigo
          </S.Link>{' '}
          .
        </S.Tutorial>
      </S.Row>

      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="client_id">Client ID</S.Label>
            <S.TextField
              {...clientIdInputRegister}
              id="client_id"
              type="text"
              placeholder="Hotmart Client ID"
              isError={errors.client_id}
            />
            {errors.client_id && <Text isErrorFeedback>{errors.client_id.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="client_secret">Client Secret</S.Label>
            <S.TextField
              {...clientSecretInputRegister}
              id="client_secret"
              type="text"
              placeholder="Hotmart Client Secret"
              isError={errors.client_secret}
            />
            {errors.client_secret && <Text isErrorFeedback>{errors.client_secret.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="basic">Basic</S.Label>
            <S.TextField
              {...basicInputRegister}
              id="basic"
              type="text"
              placeholder="Hotmart Basic"
              isError={errors.basic}
            />
            {errors.basic && <Text isErrorFeedback>{errors.basic.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="provider_id">Hottok de verificação</S.Label>
            <S.TextField
              {...hottokInputRegister}
              id="basic"
              type="text"
              placeholder="Hotmart Hottok"
              isError={errors.provider_id}
            />
            {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
          </S.InputGroup>

          <S.ButtonWrapper>
            <S.ButtonCancel type="reset" onClick={toggle}>
              Cancelar
            </S.ButtonCancel>

            <S.ButtonSave
              type="submit"
              isLoading={isSubmittingForm}
              disabled={isSubmittingForm || !hasAllInputsValue}
            >
              Salvar credencial
            </S.ButtonSave>
          </S.ButtonWrapper>
        </S.Form>
      </S.FormWrapper>
    </>
  );
};

export default Hotmart;
