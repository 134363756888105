import React from 'react';

import { useTax } from '@helpers/hooks/pages/dashboard/tax/useTax';
import PageError from '@components/common/core/Utils/PageError';
import InstantHelp from '@components/common/core/Utils/InstantHelp';
import Header from '../Header';
import HistoricTable from '../HistoricTable';
import NewTaxSidemodal from '../NewTaxSidemodal';
import HistoricSidebar from '../HistoricSidebar';
import DefaultTax from '../DefaultTax';
import SyncPastTaxModal from '../SyncPastTaxModal';
import SyncPastTaxSideModal from '../SyncPastTaxSidemodal';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { defaultTax, isValidatingTax, isTaxError, mutate } = useTax();

  if (isTaxError && !isValidatingTax) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Container>
      <Header />

      {defaultTax && <DefaultTax />}

      <HistoricTable />

      <InstantHelp
        name="tax"
        title="Impostos"
        articleLink="https://ajuda.profitfy.me/pt-br/articles/6214151-como-cadastrar-impostos"
      />

      <NewTaxSidemodal />

      <HistoricSidebar />

      <SyncPastTaxModal />

      <SyncPastTaxSideModal />
    </S.Container>
  );
};

export default Wrapper;
