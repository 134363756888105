import React from 'react';

import { Taboola } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { useTaboolaCredentials } from '@helpers/hooks/common/integrations/marketing/taboola/useTaboolaCredentials';
import { taboolaSchema } from '@helpers/validators/dashboard/integrationCenter/taboola';

import Button from '@components/common/core/Inputs/Button';
import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';

import * as S from './styles';

const NewCredentialSideModal: React.FC = () => {
  const {
    isNewCredentialSideModalOpen,
    handleNewCredentialSideModalOpen,
    handleLogin,
  } = useTaboolaCredentials();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(taboolaSchema) });

  const [clientIdValue, setClientIdValue] = React.useState<string>('');
  const [clientSecretValue, setClientSecretValue] = React.useState<string>('');

  const clientIdInputRegister = register('client_id');
  const clientSecretInputRegister = register('client_secret');

  const onClientIdChange = React.useCallback(
    event => {
      clientIdInputRegister.onChange(event);

      setClientIdValue(event.target.value);
    },
    [clientIdInputRegister],
  );

  const onClientSecretChange = React.useCallback(
    event => {
      clientSecretInputRegister.onChange(event);

      setClientSecretValue(event.target.value);
    },
    [clientSecretInputRegister],
  );

  const onSubmit = React.useCallback(
    async data => {
      await handleLogin({ client_id: data.client_id, client_secret: data.client_secret });
    },
    [handleLogin],
  );

  return (
    <S.Sidemodal isOpen={isNewCredentialSideModalOpen} toggle={handleNewCredentialSideModalOpen}>
      <S.Header>
        <Taboola size={32} />
        <S.Title>Integração com Taboola</S.Title>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Tutorial de integração
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          Para realizar a integração você precisará do <strong>client_id</strong> e do{' '}
          <strong>client_secret</strong>, para conseguir eles siga este tutorial:{' '}
          <strong>
            <S.Link
              href="https://ajuda.profitfy.me/pt-br/articles/6251974-como-integrar-com-o-taboola"
              target="_blank"
            >
              {' '}
              profitfy.me/como-integrar-com-o-taboola
            </S.Link>
            .
          </strong>
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="client_id">Client Id</S.Label>
          <S.Input
            {...clientIdInputRegister}
            value={clientIdValue}
            onChange={onClientIdChange}
            type="text"
            id="client_id"
            isError={errors.client_id}
            placeholder="Client ID Taboola"
          />
          {errors.client_id && <Text isErrorFeedback>{errors.client_id.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="client_secret">Client Secret</S.Label>
          <S.Input
            {...clientSecretInputRegister}
            value={clientSecretValue}
            onChange={onClientSecretChange}
            type="text"
            id="client_secret"
            isError={errors.client_secret}
            placeholder="Client Secret Taboola"
          />
          {errors.client_secret && <Text isErrorFeedback>{errors.client_secret.message}</Text>}
        </S.InputGroup>

        <Button type="submit">Conectar</Button>
      </Form>
    </S.Sidemodal>
  );
};

export default NewCredentialSideModal;
