import React from 'react';
import { Yampi as Icon } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { EHeadingWeight } from '@domain/enums/components/EHeading';
import { EDropshippingProvider } from '@domain/enums/common/integrations/EDropshippingProvider';
import { ESynchronizationType } from '@domain/enums/hooks/ESynchronization';
import { EYampiType } from '@domain/enums/common/integrations/providers/EYampi';

import { useToast } from '@helpers/hooks/useToast';
import { useSynchronization } from '@helpers/hooks/common/useSynchronization';
import { useDropshipping } from '@helpers/hooks/pages/onboarding/useDropshipping';
import { useDate } from '@helpers/hooks/useDate';
import { useOnboarding } from '@helpers/hooks/pages/onboarding/useOnboarding';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { yampiSchema } from '@helpers/validators/dashboard/integrationCenter/yampi';

import { DROPSHIPPING_PROVIDERS } from '@constants/common/integrations';

import yampiAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/yampi/yampiAuth';

import NextStep from '@components/Onboarding/NextStep';
import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Yampi: React.FC = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(yampiSchema) });
  const history = useHistory();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { addSynchronization } = useSynchronization();
  const { selectedGateways, selectedMarketings } = useDropshipping();
  const { format, utcToZonedTime } = useDate();
  const { sendFinancialData } = useOnboarding();

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [providerIdValue, setProviderIdValue] = React.useState<string>('');
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');
  const [secretKeyValue, setSecretKeyValue] = React.useState<string>('');

  const providerIdRegister = register('provider_id');
  const accessTokenRegister = register('access_token');
  const secretKeyRegister = register('secret_key');

  const onProviderIdValueChange = React.useCallback(
    event => {
      providerIdRegister.onChange(event);

      setProviderIdValue(event.target.value);
    },
    [providerIdRegister],
  );

  const onAccessTokenValueChange = React.useCallback(
    event => {
      accessTokenRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenRegister],
  );

  const onSecretKeyValueChange = React.useCallback(
    event => {
      secretKeyRegister.onChange(event);

      setSecretKeyValue(event.target.value);
    },
    [secretKeyRegister],
  );

  const handleAfterSubmit = React.useCallback(async () => {
    const foundShopifyIntegration = DROPSHIPPING_PROVIDERS.find(
      integration => integration.identifier === EDropshippingProvider.YAMPI,
    ) as typeof DROPSHIPPING_PROVIDERS[number];

    const { generateSyncRequest } = foundShopifyIntegration;

    const params = `?start_date=${format(new Date(2000, 1, 1), 'yyyy-MM-dd')}&end_date=${format(
      utcToZonedTime(new Date()),
      'yyyy-MM-dd',
    )}`;

    addSynchronization({
      name: 'Yampi Loja',
      type: ESynchronizationType.STORE_PRODUCT,
      label: 'Produtos',
      dependencies: [],
      storeAliasId,
      config: {
        isOnboardingSynchronization: true,
        showNotification: false,
      },
      request: generateSyncRequest(ESynchronizationType.STORE_PRODUCT, storeAliasId, params),
    });

    sendFinancialData(storeAliasId);

    if (selectedGateways.length) {
      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/gateways`);
    }

    if (!selectedGateways.length && selectedMarketings.length) {
      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/marketing`);
    }

    if (!selectedGateways.length && !selectedMarketings.length) {
      history.push(`/onboarding/${storeAliasId}/dropshipping/integrations/integration-finished`);
    }
  }, [
    addSynchronization,
    format,
    history,
    selectedGateways,
    selectedMarketings,
    storeAliasId,
    utcToZonedTime,
    sendFinancialData,
  ]);

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      try {
        await yampiAuthService.createYampiCredential({
          storeAliasId,
          data: {
            provider_id: data.provider_id,
            access_token: data.access_token,
            secret_key: data.secret_key,
            types: [EYampiType.STORE],
          },
        });

        setIsSubmittingForm(false);

        handleAfterSubmit();
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [storeAliasId, toast, handleAfterSubmit],
  );

  const hasAllInputsValue = providerIdValue && accessTokenValue && secretKeyValue;

  return (
    <S.Wrapper>
      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.Header>
            <S.Title>Integre com sua plataforma de E-commerce</S.Title>
          </S.Header>

          <S.IntegrationTitle>
            <Icon size={36} />

            <S.IntegrationTitleText fontWeight={EHeadingWeight.REGULAR}>
              Integração com Yampi
            </S.IntegrationTitleText>
          </S.IntegrationTitle>

          <S.Body>
            <S.InputGroup>
              <S.Label htmlFor="yampi-alias">Alias</S.Label>

              <S.Input
                {...providerIdRegister}
                type="text"
                id="yampi-alias"
                placeholder="Yampi Alias"
                onChange={onProviderIdValueChange}
                isError={errors.provider_id}
                value={providerIdValue}
              />
              {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="yampi-access-token">Access Token</S.Label>

              <S.Input
                {...accessTokenRegister}
                type="text"
                id="yampi-access-token"
                placeholder="Yampi Access Token"
                onChange={onAccessTokenValueChange}
                isError={errors.access_token}
                value={accessTokenValue}
              />
              {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
            </S.InputGroup>

            <S.InputGroup>
              <S.Label htmlFor="yampi-secret-key">Secret Key</S.Label>

              <S.Input
                {...secretKeyRegister}
                type="text"
                id="yampi-secret-key"
                placeholder="Yampi Secret Key"
                onChange={onSecretKeyValueChange}
                isError={errors.secret_key}
                value={secretKeyValue}
              />
              {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
            </S.InputGroup>
          </S.Body>

          <NextStep
            type="submit"
            isLoading={isSubmittingForm}
            disabled={isSubmittingForm || !hasAllInputsValue}
          >
            Próximo passo
          </NextStep>
        </S.Form>
      </S.FormWrapper>

      <S.TutorialWrapper>
        <S.TutorialImage
          src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/onboarding/images/content/onboarding/onboarding-integration-tutorial.svg"
          alt="Profitfy.me Integration Tutorial"
        />
        <S.TutorialTitle>Tutorial de integração</S.TutorialTitle>
        <S.TutorialContent>
          Para realizar a integração você precisará do <strong>Alias</strong>, do{' '}
          <strong>Access Token</strong> e da <strong>Secret Key</strong> que são encontrados neste
          link:
        </S.TutorialContent>
        <S.TutorialLink
          href="https://ajuda.profitfy.me/pt-br/articles/6253063-como-integra-com-a-loja-yampi"
          target="_blank"
        >
          Encontre suas credenciais clicando aqui!
        </S.TutorialLink>
      </S.TutorialWrapper>
    </S.Wrapper>
  );
};

export default Yampi;
