import React from 'react';

import { useParams } from 'react-router-dom';
import { IParams } from '@domain/interfaces/IParams';
import { useDate } from '@helpers/hooks/useDate';
import { COGS_FILTER_CONFIG } from '@helpers/utils/filters/cogsFilter';
import { ISelectedFilter } from '@domain/interfaces/components/IFilter';
import { parseDataFilter } from '@helpers/utils/components/filter';

import productCostService from '@services/pages/dashboard/productCost/productCost';

import Header from '@components/Dashboard/ProductCost/Header';
import Table from '@components/Dashboard/ProductCost/Table';
import Filter from '@components/common/core/Utils/Filter';
import PageError from '@components/common/core/Utils/PageError';
import InstantHelp from '@components/common/core/Utils/InstantHelp';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { storeAliasId } = useParams<IParams>();

  const { format, utcToZonedTime, subDays } = useDate();

  const [page, setPage] = React.useState<number>(0);
  const [pageCount, setPageCount] = React.useState<number>(1);
  const [searchProduct, setSearchProduct] = React.useState<string>('');
  const [filterData, setFilterData] = React.useState<Array<ISelectedFilter>>([]);

  const {
    totalPages,
    products,
    isLoading,
    isValidating,
    mutate,
    error,
  } = productCostService.getProducts({
    storeAliasId,
    page,
    startDate: format(utcToZonedTime(subDays(new Date(), 3)), 'yyyy-MM-dd'),
    endDate: format(utcToZonedTime(new Date()), 'yyyy-MM-dd'),
    filter: {
      name: searchProduct,
      ...parseDataFilter(filterData),
    },
  });

  const handleFilterData = React.useCallback(newData => {
    setFilterData(newData);
  }, []);

  const onPageChange = React.useCallback(userPage => {
    setPage(userPage.selected);
  }, []);

  const handleSearchProduct = React.useCallback(productName => setSearchProduct(productName), []);

  React.useEffect(() => {
    if (totalPages) {
      setPageCount(totalPages);
    }
  }, [totalPages]);

  if (error) {
    return <PageError mutate={mutate} />;
  }

  return (
    <S.Container>
      <Header
        filterData={filterData}
        handleFilterData={handleFilterData}
        isLoading={isLoading || isValidating}
        handleSearchProduct={handleSearchProduct}
      />

      {Boolean(filterData.length) && (
        <Filter
          filterData={filterData}
          handleFilterData={handleFilterData}
          config={COGS_FILTER_CONFIG}
        />
      )}

      <Table
        onPageChange={onPageChange}
        products={products}
        page={page}
        pageCount={pageCount}
        isLoading={isLoading || isValidating}
        mutate={mutate}
      />

      <InstantHelp
        name="product_cost"
        title="Custo dos Produtos"
        articleLink="https://ajuda.profitfy.me/pt-br/articles/5901668-como-alterar-custo-dos-produtos"
      />
    </S.Container>
  );
};

export default Wrapper;
