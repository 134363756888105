import React from 'react';

import { FixedSizeList as List } from 'react-window';

import { useRankingTable } from '@helpers/hooks/pages/dashboard/productAnalytics/useRankingTable';
import { useRetrospectiveData } from '@helpers/hooks/useRetrospectiveData';

import InstantHelp from '@components/common/core/Utils/InstantHelp';
import PageError from '@components/common/core/Utils/PageError';
import Row from '../Table/Row';
import Header from '../Header';
import Table from '../Table';
import SkeletonLoading from '../SkeletonLoading';

import * as S from './styles';

const Wrapper: React.FC = () => {
  const { trackProductAnalyticsView } = useRetrospectiveData();

  const {
    mutate,
    error,
    isLoading: isLoadingRankingTable,
    isValidating,
    isLoadingCustomValuesCategories,
    isValidatingCustomValuesCategories,
    customValuesCategoriesError,
    mutateCustomValuesCategories,
    productsMetrics,
  } = useRankingTable();

  const tableParentElementRef = React.useRef<HTMLDivElement>(null);

  const mutatePage = React.useCallback(async () => {
    const promises = [];

    if (error) promises.push(mutate());

    if (customValuesCategoriesError) promises.push(mutateCustomValuesCategories());

    await Promise.all(promises);
  }, [error, mutate, customValuesCategoriesError, mutateCustomValuesCategories]);

  React.useEffect(() => {
    trackProductAnalyticsView();
  }, [trackProductAnalyticsView]);

  if (
    (error && !isValidating) ||
    (customValuesCategoriesError && !isValidatingCustomValuesCategories)
  ) {
    return <PageError mutate={mutatePage} />;
  }

  const offsetComparedToTop =
    window.pageYOffset + (tableParentElementRef?.current?.getBoundingClientRect().top || 0) || 0;
  const tableHeader = 42;
  const maxHeight =
    window.innerHeight > 720
      ? window.innerHeight - offsetComparedToTop - tableHeader
      : window.innerHeight - tableHeader - 32;
  const mappedItemsLength = productsMetrics?.length || 0;

  const listHeight = mappedItemsLength * 74 >= maxHeight ? maxHeight : mappedItemsLength * 74 + 18;

  const isLoading =
    isLoadingRankingTable ||
    isValidating ||
    isLoadingCustomValuesCategories ||
    isValidatingCustomValuesCategories;

  return (
    <S.Container ref={tableParentElementRef}>
      <Header />

      {isLoading && <SkeletonLoading />}

      {!isLoading && (
        <S.TableWrapper>
          {Boolean(productsMetrics.length) && (
            <List
              height={listHeight}
              innerElementType={Table}
              itemCount={productsMetrics.length}
              itemSize={74}
              width="100%"
            >
              {Row}
            </List>
          )}
        </S.TableWrapper>
      )}

      <InstantHelp
        name="product_analytics"
        title="Análise de Produtos"
        articleLink="https://ajuda.profitfy.me/pt-br/articles/6461181-como-funciona-a-analise-de-produto-individual"
      />
    </S.Container>
  );
};

export default Wrapper;
