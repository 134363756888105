import { ShopifyPlusYampiOnboarding, Store } from '@profitfy/pakkun-icons';
import React from 'react';
import { useParams } from 'react-router-dom';

import { EEcommerceProvider } from '@domain/enums/common/integrations/EEcommerceProvider';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { EButtonVariant } from '@domain/enums/components/EButton';
import { IParams } from '@domain/interfaces/IParams';
import { ISidemodalProps } from '@domain/interfaces/onboarding/integrations/ISidemodal';

import { useEcommerce } from '@helpers/hooks/pages/onboarding/useEcommerce';
import { useConfig } from '@helpers/hooks/useConfig';
import { useMediaQueryHeight } from '@helpers/hooks/useMediaQuery';

import { ECOMMERCE_PROVIDERS } from '@constants/common/integrations';

import SuggestionModal from './SuggestionModal';

import Save from './Save';

import * as S from './styles';

const SideModal: React.FC<ISidemodalProps> = ({ isOpen, toggle }) => {
  const { handleSelectedProvider, selectedProvider, storeIntegrations } = useEcommerce();
  const { analytics, user } = useConfig();
  const { storeAliasId } = useParams<IParams>();

  const mobileHeight = useMediaQueryHeight('768px');

  const [selectedPlatform, setSelectedPlatform] = React.useState<EEcommerceProvider | undefined>(
    undefined,
  );
  const [isSuggestionModalOpen, setIsSuggestionModalOpen] = React.useState<boolean>(false);
  const [showErpProvider, setShowErpProvider] = React.useState<boolean>(false);

  const handleSuggestionModalOpen = React.useCallback(
    () => setIsSuggestionModalOpen(!isSuggestionModalOpen),
    [isSuggestionModalOpen],
  );

  const openSuggestionModal = React.useCallback(() => {
    handleSuggestionModalOpen();
  }, [handleSuggestionModalOpen]);

  const onChange = React.useCallback(event => {
    setSelectedPlatform(event.target.value);
  }, []);

  const onSave = React.useCallback(() => {
    const foundProvider = ECOMMERCE_PROVIDERS.find(
      integration => integration.identifier === selectedPlatform,
    );

    handleSelectedProvider(selectedPlatform);

    analytics?.track(
      'Onboarding Selected E-commerce',
      {
        ecommerce_provider: foundProvider?.name,
        email: user?.email,
      },
      { context: { groupId: storeAliasId } },
    );
    analytics?.trackHubSpot(storeAliasId, { ecom_platform: foundProvider?.name });

    toggle();
  }, [selectedPlatform, handleSelectedProvider, toggle, analytics, storeAliasId, user]);

  const hasProviderIntegration = storeIntegrations?.store_front.find(
    provider => provider.is_active,
  );

  const filteredProviders = ECOMMERCE_PROVIDERS.filter(provider => {
    if (showErpProvider && !provider.isErp) return false;

    if (!showErpProvider && provider.isErp) return false;

    return true;
  }, []);

  const toggleErp = React.useCallback(() => setShowErpProvider(state => !state), []);

  return (
    <>
      <S.Sidemodal isOpen={isOpen} toggle={toggle} blockOverflowYScroll={mobileHeight}>
        <S.Header>
          <Store size={24} />
          <S.Title fontWeight={EHeadingWeight.REGULAR} type={EHeadingSize.H4}>
            Selecionar plataformas de ecommerce
          </S.Title>
        </S.Header>

        <S.Body>
          {/* <S.InfoWrapper>
            <S.NoIntegrationText>
              É necessário integrar com sua loja virtual. Caso não encontre sua plataforma para
              integração, integre diretamente com ERP{' '}
              <S.ErpAndStoreButton onClick={toggleErp}> clicando aqui</S.ErpAndStoreButton>
            </S.NoIntegrationText>
          </S.InfoWrapper> */}

          <S.IntegrationsWrapper>
            {filteredProviders.map(integration => {
              const Icon = integration.icon;

              const isShopifyPlusYampiIntegration =
                integration.name === 'Checkout Yampi + Loja Shopify';

              const isBeta =
                integration?.identifier === EEcommerceProvider.TINY ||
                integration?.identifier === EEcommerceProvider.BLING;

              return (
                <S.InputGroup key={integration.identifier}>
                  <S.RadioLabel>
                    <S.RadioTextWrapper>
                      {isShopifyPlusYampiIntegration && <ShopifyPlusYampiOnboarding size={24} />}

                      {!isShopifyPlusYampiIntegration && <Icon size={24} />}
                      <S.RadioText>{integration.name}</S.RadioText>

                      {isBeta && <S.BetaBadge>Beta</S.BetaBadge>}
                    </S.RadioTextWrapper>

                    <S.Radio
                      value={integration.identifier}
                      name="platform"
                      defaultChecked={
                        selectedPlatform
                          ? selectedPlatform === integration.identifier
                          : selectedProvider === integration.identifier
                      }
                      onChange={onChange}
                      id={`${integration.identifier}-option`}
                      disabled={Boolean(hasProviderIntegration)}
                    />
                  </S.RadioLabel>
                </S.InputGroup>
              );
            })}
          </S.IntegrationsWrapper>
          {/* <S.ErpButton variant={EButtonVariant.SECONDARY_BUTTON} onClick={toggleErp}>
            {!showErpProvider
              ? 'Não encontrou a integração? Veja a lista de ERPs'
              : 'Mostrar integrações de e-commerce'}
          </S.ErpButton> */}

          <S.SuggestionText>
            Não encontrou a plataforma que você usa?{' '}
            <S.SuggestionButton onClick={openSuggestionModal}>
              Nos informe clicando aqui.
            </S.SuggestionButton>
          </S.SuggestionText>
        </S.Body>

        <Save onCancel={toggle} onSave={onSave} />
      </S.Sidemodal>

      <SuggestionModal isOpen={isSuggestionModalOpen} toggle={handleSuggestionModalOpen} />
    </>
  );
};

export default SideModal;
