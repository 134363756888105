import React from 'react';
import Gleap from 'gleap';

import { useParams } from 'react-router-dom';
import { Shopify } from '@profitfy/pakkun-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';
import { IUpdateShopifyCredentialSideModal } from '@domain/interfaces/IUpdateShopifyCredentialSideModal';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useConfig } from '@helpers/hooks/useConfig';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { SHOPIFY_SCOPES } from '@constants/shopifyScopes';
import { shopifySchema } from '@helpers/validators/dashboard/integrationCenter/shopifyCredentials';

import shopifyService from '@services/pages/dashboard/integrationCenter/marketplace/shopify/shopifyAuth';

import Save from '@components/Dashboard/IntegrationCenter/SideModalContent/Save';
import Text from '@components/common/core/DataDisplay/Text';
import Form from '@components/common/core/Inputs/Form';
import CredentialErrorModal from '@components/Integrations/StoreProvider/Shopify/CredentialErrorModal';
import NoScopesModal from '@components/Integrations/StoreProvider/Shopify/NoScopesModal';

import { EShopifyType } from '@domain/enums/common/integrations/providers/EShopify';
import * as S from './styles';

const SideModalContent: React.FC<IUpdateShopifyCredentialSideModal> = ({ domain }) => {
  const { mutateStore } = useStoreConfig();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { analytics } = useConfig();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(shopifySchema),
  });

  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);
  const [isNoScopesModalOpen, setIsNoScopesModalOpen] = React.useState<boolean>(false);
  const [missingScopes, setMissingScopes] = React.useState<Array<string>>([]);
  const [isCredentialErrorModalOpen, setIsCredentialErrorModalOpen] = React.useState<boolean>(
    false,
  );

  const handleNoScopesModalOpen = React.useCallback(() => {
    setIsNoScopesModalOpen(currentState => !currentState);
  }, []);

  const handleCredentialErrorModalOpen = React.useCallback(() => {
    setIsCredentialErrorModalOpen(currentState => !currentState);
  }, []);

  const handleFailureSubmit = React.useCallback(
    error => {
      const errorMessage = error?.response?.data?.message as string;

      const isShopifyUnauthorizedError = errorMessage === 'Shopify unauthorized';

      const isNoScopeError = errorMessage.includes('Missing the following scope(s)');

      if (isShopifyUnauthorizedError) handleCredentialErrorModalOpen();

      if (isNoScopeError) {
        const filteredShopifyScopes = SHOPIFY_SCOPES.filter(scope => {
          if (errorMessage.includes(scope)) return true;

          return false;
        });

        if (filteredShopifyScopes) {
          setMissingScopes(filteredShopifyScopes);
          handleNoScopesModalOpen();
        }
      }

      if (!isShopifyUnauthorizedError && !isNoScopeError)
        toast.error(getTranslatedIntegrationsError(error));

      setIsCreatingCredential(false);
    },
    [handleCredentialErrorModalOpen, handleNoScopesModalOpen, toast],
  );

  const sanitizeUrl = React.useCallback((url: string) => {
    const replacedUrl = url.replace('https://', '').replace('wwww.', '');

    const parsedUrl = replacedUrl.split('.myshopify.com')[0];

    return parsedUrl;
  }, []);

  const onSubmit = React.useCallback(
    async formData => {
      setIsCreatingCredential(true);

      const sanitizedUrl = sanitizeUrl(formData.shopify_url);

      const storeDomain = `${sanitizedUrl}.myshopify.com`;

      const data = {
        store_domain: storeDomain,
        access_token: formData.access_token,
        secret_api_key: formData.secret_key,
        types: [EShopifyType.STORE],
      };

      try {
        await shopifyService.deleteCredential(storeAliasId);

        await shopifyService.createCredential({ storeAliasId, data });

        mutateStore();

        toast.success('Credencial atualizada com sucesso.');

        setIsCreatingCredential(false);
      } catch (error: any) {
        handleFailureSubmit(error);
      }
    },
    [sanitizeUrl, storeAliasId, toast, mutateStore, handleFailureSubmit],
  );

  const trackArticleClick = React.useCallback(() => {
    analytics?.track(
      'Shopify Integration Article Clicked',
      {
        click_source: 'dashboard_form',
      },
      { context: { groupId: storeAliasId } },
    );
  }, [analytics, storeAliasId]);

  const openArticle = React.useCallback(() => {
    try {
      Gleap.openHelpCenterArticle('5901324', false);
    } catch (error: any) {
      throw new Error(error);
    }
  }, []);

  React.useEffect(() => {
    if (domain) {
      const parsedDomain = domain.replace('.myshopify.com', '');

      setValue('shopify_url', parsedDomain);
    }
  }, [domain, setValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      openArticle();
    }, 7000);

    return () => clearTimeout(timeout);
  }, [openArticle]);

  return (
    <S.ContentWrapper>
      <S.Header>
        <Shopify size={52} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Shopify
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>
        <S.FunctionalityDescriptionText>
          A integração com sua plataforma de e-commerce serve para puxar seus dados de loja.
        </S.FunctionalityDescriptionText>
      </S.FunctionalityDescriptionWrapper>

      <S.TutorialWrapper>
        <S.TutorialHeading type={EHeadingSize.H5}>Tutorial de integração</S.TutorialHeading>

        <S.TutorialText>
          Informe a URL da Loja, o Access Token e a Secret Key. Para saber como obter essas
          informações, <strong>veja o nosso tutorial:</strong>
        </S.TutorialText>

        <S.ArticleLink
          href="https://ajuda.profitfy.me/pt-br/articles/5901324-como-integrar-com-a-loja-shopify"
          target="_blank"
          onClick={trackArticleClick}
        >
          <S.IntegrationArticleImage
            src="https://s3.us-west-2.amazonaws.com/app.profitfy.me-media/integrations/shopify/shopify-how-to-integrate.jpg"
            alt="Profitfy Shopify Integration"
          />
        </S.ArticleLink>
      </S.TutorialWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="shopify-store-url">URL da Loja</S.Label>

          <S.InputWrapper readOnly={Boolean(domain)}>
            <S.Input
              {...register('shopify_url')}
              type="text"
              id="shopify-store-url"
              readOnly={Boolean(domain)}
            />

            <S.AppendContent>.myshopify.com</S.AppendContent>
          </S.InputWrapper>
          {errors.shopify_url && <Text isErrorFeedback>{errors.shopify_url.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="shopify-access-token">Token de Acesso da API Admin</S.Label>

          <S.Input
            {...register('access_token')}
            type="text"
            id="shopify-access-token"
            placeholder="Token de Acesso da API Admin"
          />
          {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="shopify-secret-key">Chave secreta da API</S.Label>

          <S.Input
            {...register('secret_key')}
            type="text"
            id="shopify-secret-key"
            placeholder="Chave secreta da API"
          />
          {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
        </S.InputGroup>

        <Save
          hasCredentials={false}
          isSubmitting={isCreatingCredential}
          isSaveButtonDisabled={isCreatingCredential}
          submitText="Salvar alterações"
          cancelText="Cancelar"
        />
      </Form>

      <CredentialErrorModal
        isOpen={isCredentialErrorModalOpen}
        toggle={handleCredentialErrorModalOpen}
      />

      <NoScopesModal
        isOpen={isNoScopesModalOpen}
        toggle={handleNoScopesModalOpen}
        missingScopes={missingScopes}
      />
    </S.ContentWrapper>
  );
};

export default SideModalContent;
