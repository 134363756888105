import React from 'react';

import * as S from './styles';

const TermsOfUsageContent: React.FC = () => {
  return (
    <S.Content>
      <S.Title>Termos e Condições de Uso</S.Title>

      <S.Paragraph>
        O uso da plataforma digital desenvolvida e disponibilizada pela PROFITFY TECNOLOGIA LTDA.
        (“PROFITFY”) está condicionada à prévia aceitação e cumprimento aos presentes Termos e
        Condições de Uso e Serviços (“Termos de Uso”) ora descritos, os quais estão adequados às
        exigências previstas na Lei nº 10.406/2002 (“Código Civil Brasileiro”), na Lei nº
        12.965/2014 (“Marco Civil da Internet”), na Lei nº 13.709/2018 (“Lei Geral de Proteção de
        Dados Pessoais”) e demais legislações pertinentes.
      </S.Paragraph>
      <S.Paragraph>
        Nestes Termos de Uso, junto de nossas{' '}
        <S.ExternalLink href="https://app.profitfy.me/privacy-policy" target="_blank  ">
          Políticas de Privacidade
        </S.ExternalLink>
        , encontram-se dispostas as regras e condições aplicáveis ao acesso, uso dos serviços e
        operações desta plataforma digital. Estes produtos e serviços são oferecidos pela
        <strong> PROFITFY TECNOLOGIA LTDA. (“PROFITFY”)</strong>, inscrita no CNPJ/ME sob o nº
        37.365.788/0001-40, com sede na Avenida General Ataliba Lionel, 1205, 9º andar, sala 92,
        Santana, São Paulo/SP, CEP 02033-000.
      </S.Paragraph>
      <S.Paragraph>
        A utilização da plataforma e contratação de nossos produtos e/ou serviços fica submetida à
        prévia aceitação destes Termos de Uso, sendo exigida do Usuário a sua prévia leitura e
        concordância com nossas regras e{' '}
        <S.ExternalLink href="https://app.profitfy.me/privacy-policy" target="_blank  ">
          Políticas de Privacidade
        </S.ExternalLink>
        . Após a leitura dos Termos e Condições de Uso e da{' '}
        <S.ExternalLink href="https://app.profitfy.me/privacy-policy" target="_blank  ">
          Política de Privacidade
        </S.ExternalLink>
        , o Usuário confirma que compreendeu e aceitou com todas as previsões destes documentos.
      </S.Paragraph>
      <S.Paragraph>
        A <strong>PROFITFY</strong> poderá, a qualquer tempo, modificar estes Termos e Condições de
        Uso mediante notificação prévia dos Usuários, a ser enviada ao e-mail cadastrado pelo
        Usuário na Plataforma <strong>ou</strong> mediante a disponibilização de aviso na própria
        Plataforma. O uso da Plataforma ou contratação dos Produtos e Serviços após a entrada em
        vigor das modificações dos Termos e Condições{' '}
        <strong>
          constituirá aceitação e concordância do Usuário quanto aos novos termos contratuais.
        </strong>
      </S.Paragraph>
      <S.Paragraph>
        Na hipótese de o Usuário não concordar com as alterações ou modificações, este poderá, a
        qualquer momento, solicitar o cancelamento de sua Conta.
      </S.Paragraph>
      <S.Paragraph underline>
        PARA MANIFESTAR SUA CONCORDÂNCIA COM ESTES TERMOS DE USO, O USUÁRIO DEVE CLICAR EM “EU
        ACEITO”, ACEITANDO TODAS AS REGRAS PREVISTAS PARA UTILIZAÇÃO DA PLATAFORMA DIGITAL. TODAS
        ESTAS DISPOSIÇÕES REGEM A RELAÇÃO ENTRE <strong>PROFITFY</strong> E O USUÁRIO. NA HIPÓTESE
        DO USUÁRIO NÃO CONCORDAR COM OS TERMOS DE USO, ESTE NÃO PODERÁ ACESSAR A PLATAFORMA OU
        UTILIZAR NOSSOS SERVIÇOS.
      </S.Paragraph>

      <S.Subtitle>1. Registro de Usuário</S.Subtitle>
      <S.Paragraph>
        Para a utilização da plataforma, o Usuário – pessoa física ou jurídica que esteja em pleno e
        total gozo da capacidade civil, bem como esteja apto à prática de todo e qualquer ato
        necessário à validade das solicitações de serviços requeridas, nos termos da legislação
        aplicável – deverá realizar seu cadastro na plataforma.
      </S.Paragraph>
      <S.Paragraph>
        Ao se cadastrar, o Usuário se compromete a fornecer informações verídicas, completas e
        atualizadas, sob pena das consequências jurídicas e legais da apresentação de informações
        falsas.
      </S.Paragraph>
      <S.Paragraph underline>
        O login e a senha criados pelo Usuário são pessoais e intransferíveis, sendo o Usuário seu
        único e exclusivo responsável por mantê-las em segurança e sigilo, evitando, pois, o uso não
        autorizado de seu Cadastro por terceiros.
      </S.Paragraph>
      <S.Paragraph>
        Destacamos que o Usuário somente poderá estar “logado” em um dispositivo móvel por vez, não
        sendo possível a sua utilização simultânea em mais de um dispositivo móvel.
      </S.Paragraph>
      <S.Paragraph>
        Quando o Usuário realizar seu login pela primeira vez, deverá ser confirmado seu endereço de
        e-mail. Após esta etapa, qualquer login pelo Usuário poderá ser realizado exclusivamente com
        seu token.
      </S.Paragraph>
      <S.Paragraph>
        Possibilitamos, ainda, que o token seja substituído pelo Face ID de seu dispositivo móvel.
      </S.Paragraph>

      <S.Subtitle>2. Da Plataforma Digital da Profitfy</S.Subtitle>
      <S.Paragraph>
        A plataforma digital da <strong>PROFITFY</strong> oferece uma ferramenta de administração
        financeira e vendas para lojas online, bem como para a contratação de outros produtos e/ou
        serviços oferecidos pela <strong>PROFITFY</strong>, obedecidas as disposições destes Termos
        de Uso.
      </S.Paragraph>
      <S.Paragraph>
        Nossa atividade principal consiste no oferecimento de ferramentas para ajudar sua loja
        on-line na administração de suas operações diárias, contemplando as áreas de finanças,
        marketing, estoque e vendas, reunindo dados e métricas de modo a explorar o potencial máximo
        de seu negócio, tornando-o mais lucrativo.
      </S.Paragraph>
      <S.Paragraph>
        Ressaltamos que, para melhor uso de nossa plataforma, o Usuário deverá constantemente
        prestar as informações devidas de sua loja online para possibilitar uma melhor precisão nas
        informações oferecidas por nosso serviço.
      </S.Paragraph>
      <S.Paragraph>
        Além disto, contamos com integrações via API com outras plataformas que podem ser utilizadas
        para alimentar automaticamente as informações necessárias para o aproveitamento de nossos
        serviços. Contudo, a <strong>PROFITFY</strong> não se responsabiliza pelo preenchimento
        incorreto, pelo Usuário, das informações constantes em nossa plataforma ou outras
        plataformas integradas, que podem trazer imprecisão no resultado de nossos serviços.
      </S.Paragraph>
      <S.Paragraph underline uppercase>
        Produtos e Serviços
      </S.Paragraph>
      <S.Paragraph>
        O Usuário poderá utilizar os serviços da <strong>PROFITFY</strong> em nossa plataforma
        digital, para tomar melhores decisões em sua Loja Online, mediante a integração com outras
        plataformas que utiliza em seu negócio e a alimentação de nosso software com suas
        informações.
      </S.Paragraph>
      <S.Paragraph>
        Em nossa plataforma, o Usuário contará com campos respectivos para lançamento de estoque,
        produtos oferecidos, custos operacionais, despesas com marketing/publicidade, impostos
        incidentes de acordo com o regime tributário adotado, tarifas e quaisquer outros custos que
        o Usuário venha a informar.
      </S.Paragraph>
      <S.Paragraph>
        Possibilitamos o fácil preenchimento de informações de seu negócio por meio de integrações
        com API’s que são utilizadas na Loja Online do Usuário, e que deverão ser autorizadas por
        este no momento do Setup. Caso algumas informações não sejam preenchidas automaticamente via
        API, é imprescindível que o Usuário realize o seu preenchimento manualmente.
      </S.Paragraph>
      <S.Paragraph>
        Com nossa solução, o Usuário terá a centralização dos dados de seu negócio, para controle de
        custos com fornecedores, plataformas de e-commerce, gateway de pagamento e plataformas de
        marketing, por meio de um Dashboard financeiro de fácil visualização para análise de sua
        empresa.
      </S.Paragraph>
      <S.Paragraph>
        Também oferecemos a análise de produtos individuais, trazendo praticidade ao nosso Usuário
        para entender como seu produto performou em campanhas de publicidade, podendo compreender
        melhor seus gargalos e melhorar o que deve ser melhorado.
      </S.Paragraph>
      <S.Paragraph>
        O Usuário também poderá ter acesso a outras abas como controle de pedidos, boletos, cartões,
        taxas, estornos, devolvidos, valores adicionais, análise de campanhas, entre outras
        funcionalidades que qualificam a performance de suas decisões, proporcionando a melhora de
        seu negócio e maior lucratividade.
      </S.Paragraph>
      <S.Paragraph>
        A <strong>PROFITFY</strong> pede a atenção de seus Usuários aos produtos adquiridos em dólar
        ou outras moedas estrangeiras. Nossa plataforma realiza o cálculo de moedas estrangeiras no
        valor do câmbio comercial, acrescido de IOF e taxa de spread, mas este valor pode não ser
        compatível com o real valor da moeda no momento da aquisição. Para isto, é imprescindível
        que o Usuário confirme essas informações para não verificar inexatidão em seu demonstrativo
        financeiro.
      </S.Paragraph>
      <S.Paragraph>
        Possibilitamos que os Usuários preencham manualmente os valores de
        impostos/taxas/contribuições pagos, bem como custos e despesas adicionais de seu negócio,
        para viabilizar informações financeiras precisas.
      </S.Paragraph>
      <S.Paragraph>
        Destacamos que nossa planilha financeira não se trata de Balanço Contábil ou de
        Demonstrativo de Resultado do Exercício (DRE), utilizados para fins contábeis. O produto
        digital oferecido pela <strong>PROFITFY</strong> não substitui serviços contábeis.
      </S.Paragraph>
      <S.Paragraph>
        O Usuário declara ter ciência de que a <strong>PROFITFY</strong> se limita a processar e
        facilitar a compreensão dos dados de seu negócio, mas depende da fiel prestação de
        informações pelo Usuário e as integrações com plataformas que utiliza em sua Loja Online.
      </S.Paragraph>
      <S.Paragraph>
        A <strong>PROFITFY</strong> não se responsabiliza pelo preenchimento incorreto ou inexato de
        informações pelo Usuário, ou pelo envio de dados incorretos pela integração com a plataforma
        de empresa terceira, que o Usuário utiliza em seu negócio.
      </S.Paragraph>

      <S.Subtitle>3. Planos Oferecidos Pela Profitfy</S.Subtitle>
      <S.Paragraph>
        Em nossa plataforma, o Usuário poderá verificar os planos vigentes para utilização de nossos
        serviços.
      </S.Paragraph>
      <S.Paragraph>
        Cada plano contempla uma gama de serviços oferecidos pela <strong>PROFITFY</strong>, que
        poderá ser alterado unilateralmente por nossa empresa, mediante o prévio aviso ao Usuário.
      </S.Paragraph>
      <S.Paragraph>
        Em nossos planos, temos benefícios como prazo de armazenamento de dados retroativos, limite
        de pedidos pagos mensais, histórico dos últimos 30 (trinta) dias, quantidade de integrações
        com plataformas de anúncio, marketplaces e lojas virtuais, exportação de relatório para
        Excel, análise de produto, suporte e assessoria, dentre outras variáveis.
      </S.Paragraph>
      <S.Paragraph>
        É imprescindível que o Usuário verifique qual plano se adéqua melhor a sua necessidade,
        oportunizando sua melhor experiência em nossa plataforma.
      </S.Paragraph>
      <S.Paragraph>
        Destacamos, ainda, que novos Usuários poderão utilizar gratuitamente nossa plataforma por 07
        (sete) dias.
      </S.Paragraph>
      <S.Paragraph>
        Todos os planos ofertados pela Profitfy são renovados automaticamente, por períodos iguais e
        sucessivos ao originalmente contratado, até eventual pedido de cancelamento do plano pelo
        Usuário.
      </S.Paragraph>
      <S.Paragraph>
        Caso o Usuário opte pelo cancelamento ou pela alteração do plano, deverá fazê-lo com
        antecedência mínima de 3 (três) dias do término do período contratado diretamente na aba
        &quot;Minha assinatura&quot;.
      </S.Paragraph>

      <S.Subtitle>4. Pagamento</S.Subtitle>
      <S.Paragraph>
        Para aderir aos nossos planos, admitimos apenas pagamento por meio de cartão de crédito.
      </S.Paragraph>
      <S.Paragraph>Você concorda em:</S.Paragraph>
      <S.Paragraph>
        <strong>a)</strong> fornecer informações corretas de seu cartão de crédito;
        <br />
        <br />
        <strong>b)</strong> atualizar imediatamente as informações de contato e pagamento, para que
        possamos entrar em contato com você conforme necessário;
        <br />
        <br />
        <strong>c)</strong> nos autorizar a cobrar de seu provedor de pagamentos escolhido por tais
        valores ao fazer seu pagamento via cartão de crédito.
      </S.Paragraph>
      <S.Paragraph>
        Ressaltamos que, na hipótese de inadimplemento da mensalidade do plano pelo Usuário, a{' '}
        <strong>PROFITFY</strong> suspenderá imediatamente seus serviços. O plano será restabelecido
        em até 48 (quarenta e oito) horas após a confirmação de pagamento pela equipe da{' '}
        <strong>PROFITFY.</strong>
      </S.Paragraph>
      <S.Paragraph>
        Eventuais pedidos excedentes ao plano contratado pelo Usuário, serão cobrados juntamente da
        renovação do plano.
      </S.Paragraph>
      <S.Paragraph>
        A <strong>PROFITFY</strong> poderá atualizar, a qualquer momento, com base no IGP-M ou IPCA,
        ou outro índice que venha a substitui-lo, o preço dos planos e dos pedidos no momento da
        renovação do contrato mensal, trimestral ou semestral.
      </S.Paragraph>

      <S.Subtitle>5. Das Obrigações e Deveres dos Usuários</S.Subtitle>
      <S.Paragraph>
        O Usuário declara possuir plena ciência de que, ao utilizar os serviços de{' '}
        <strong>PROFITFY</strong>, deverá se comportar em estrita observância a estes Termos de Uso
        e à{' '}
        <S.ExternalLink href="https://app.profitfy.me/privacy-policy" target="_blank  ">
          Política de Privacidade
        </S.ExternalLink>{' '}
        de nossa empresa, respeitando a legislação vigente, a moral e os bons costumes. O usuário
        concorda, ainda, em não utilizar os serviços oferecidos em nossa plataforma para fins
        ilícitos, não lesar os direitos e interesses da <strong>PROFITFY</strong>, de outros
        usuários e/ou terceiros, ou de qualquer forma prejudicar, desativar ou sobrecarregar os
        serviços, impedindo a sua normal utilização pela <strong>PROFITFY</strong>, outros usuários
        e/ou terceiros, devendo preservar as disposições destes Termos de Uso.
      </S.Paragraph>
      <S.Paragraph>
        O usuário concorda em abster-se da prática de quaisquer dos atos abaixo descritos:
      </S.Paragraph>
      <S.Paragraph>
        <strong>a)</strong> Alterar ou violar, de qualquer forma, os sistemas de autenticação,
        verificação de identidade e/ou de segurança dos serviços, redes ou cadastros de usuários
        e/ou administradores responsáveis pelos serviços, incluindo, mas não se limitando a
        tentativas de acessos a dados não destinados ao usuário, tentativa de acesso aos serviços ou
        contas sem autorização expressa para fazê-lo, ou tentativa de acesso ou alteração, de
        qualquer maneira e em qualquer nível de segurança, à rede da <strong>PROFITFY</strong>;
        <br />
        <br />
        <strong>b)</strong> Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia,
        reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer
        modalidades, gratuita ou onerosamente, provisória ou permanentemente, o software de
        propriedade exclusiva da <strong>PROFITFY</strong>, assim como quaisquer informações a ele
        relativas;
        <br />
        <br />
        <strong>c)</strong> Promover, de forma proposital, interrupções, mudanças ou cortes nas
        comunicações do site e/ou nos serviços disponibilizados, bem como, efetuar ataques
        cibernéticos ou similares;
        <br />
        <br />
        <strong>d)</strong> Efetuar qualquer tipo de monitoramento que envolva a interceptação de
        informações que não se destinem ao Usuário, remeter ou transmitir arquivos com vírus ou
        outras características destrutivas, que possam afetar de forma prejudicial a operação de um
        computador e/ou o bom funcionamento do site;
        <br />
        <br />
        <strong>e)</strong> Utilizar qualquer programa de computador ou qualquer outra forma que
        induza a erro, com o objetivo de obter vantagens patrimoniais ou comerciais em favor do
        usuário ou de terceiros, não autorizadas pela <strong>PROFITFY</strong> ou em desarmonia com
        estes Termos de Uso;
        <br />
        <br />
        <strong>f)</strong> Efetuar ações que limitem, deneguem ou vetem qualquer usuário de acessar
        o site e utilizar os serviços.
      </S.Paragraph>
      <S.Paragraph>
        O usuário será responsável, na esfera cível e criminal, pelos danos patrimoniais e
        extrapatrimoniais, ou por qualquer outro prejuízo que venha a causar à{' '}
        <strong>PROFITFY</strong>, terceiros e outros usuários, em razão de descumprimento ou não de
        qualquer das disposições preconizadas nestes Termos de Uso, ou em quaisquer outros avisos,
        regulamentos de uso, instruções, políticas e/ou regulamentos editados e devidamente
        publicados pela <strong>PROFITFY</strong> em seu site.
      </S.Paragraph>

      <S.Subtitle>6. Licença de Uso da Plataforma</S.Subtitle>
      <S.Paragraph>
        Nos termos dos presentes Termos de Uso, mediante contratação de qualquer de nossos planos,
        concedemos ao Usuário uma licença revogável, não exclusiva e intransferível para uso de
        nossa plataforma digital. O Usuário não poderá utilizar ou permitir que nosso software seja
        utilizado para outra finalidade que não seja aquela prevista nestes Termos de Uso. Esta
        licença não deve ser interpretada extensivamente, reputando-se restritiva aos serviços
        contratados quando da adesão ao plano escolhido pelo Usuário.
      </S.Paragraph>
      <S.Paragraph>
        Ressaltamos que, em hipótese alguma, o Usuário poderá terá acesso ao código fonte do
        software, sendo este de propriedade intelectual exclusiva da <strong>PROFITFY.</strong>
      </S.Paragraph>

      <S.Subtitle>
        7. Dos Limites da Responsabilidade Pelas Serviços Prestados Pela Profitfy
      </S.Subtitle>
      <S.Paragraph>
        A <strong>PROFITFY</strong> possui contrato com acesso à internet e servidores de terceiros
        para o desenvolvimento dos serviços/produtos que se dedica, motivo pelo qual o Usuário
        reconhece que tais acessos poderão, eventualmente, estar indisponíveis em decorrência de
        problemas técnicos, falhas na internet ou provedor, bem como por qualquer outra razão alheia
        a atos da <strong>PROFITFY</strong>, incluindo, eventos de caso fortuito ou de força maior.
        Desse modo, a <strong>PROFITFY</strong> não garante a disponibilidade, de forma contínua e
        ininterrupta, do funcionamento dos serviços prestados.
      </S.Paragraph>
      <S.Paragraph>
        A <strong>PROFITFY</strong> será isenta de qualquer responsabilidade por danos e prejuízos
        de qualquer natureza que sejam decorrentes, de forma direta ou indireta, da interrupção ou
        suspensão, falha, cessação, falta de disponibilidade ou da descontinuação do funcionamento
        dos serviços prestados.
      </S.Paragraph>
      <S.Paragraph>
        O Usuário será o único responsável pelas informações remetidas à <strong>PROFITFY</strong> e
        pelo cumprimento das obrigações dela decorrentes.
      </S.Paragraph>
      <S.Paragraph>
        Sem prejuízo dos demais direitos previstos em lei, a <strong>PROFITFY</strong> tem
        resguardado o direito de regresso em face do Usuário, em razão de quaisquer danos materiais
        e/ou morais que eventualmente vierem a ser demandados contra a <strong>PROFITFY</strong>, em
        juízo ou fora dele, ou, ainda, que a <strong>PROFITFY</strong> venha a sofrer, em
        decorrência do descumprimento de obrigações do usuário, resultante dos serviços
        disponibilizados na plataforma digital.
      </S.Paragraph>

      <S.Subtitle>8. Da Propriedade Intelectual e dos Direitos Reservados</S.Subtitle>
      <S.Paragraph>
        Ressaltamos que todos os direitos de propriedade intelectual atinentes ao sítio eletrônico
        pertencem à <strong>PROFITFY</strong>, estejam tais direitos registrados ou não. Assim, o
        Usuário concorda em se abster de pleitear ou reclamar, a qualquer tempo, tais direitos, como
        se fossem de sua titularidade.
      </S.Paragraph>
      <S.Paragraph>
        A <strong>PROFITFY</strong> é titular de direitos sobre todos os bancos de dados, conteúdos,
        imagens, softwares utilizados no funcionamento do site, amparados de acordo com a legislação
        aplicável à propriedade intelectual e proteção de dados.
      </S.Paragraph>
      <S.Paragraph>
        Os Usuários estão cientes e concordam que a <strong>PROFITFY</strong> é a única e exclusiva
        titular de todos os direitos autorais e de propriedade intelectual atinentes à plataforma
        digital, abrangendo, neste sentido, nome de domínio, programação, banco de dados, conteúdo,
        arquivo, funcionalidades, design e outras características que se fazem necessárias –
        respectivos direitos nos são amparados por lei.
      </S.Paragraph>
      <S.Paragraph>
        A utilização do nome comercial <strong>PROFITFY</strong>, bem como nossos produtos e
        serviços associados ao mesmo, de forma não autorizada sofrerá as consequências legais, haja
        vista a exclusiva titularidade que recai sobre nós.
      </S.Paragraph>
      <S.Paragraph>
        Os presentes Termos de Uso não acarretam a cessão ou transferência ao usuário de quaisquer
        direitos relativos ao site, ou, então, qualquer parte de seu conteúdo. Entretanto, os
        Usuários estão aptos a navegar na plataforma apenas nos estritos termos permitidos nos
        presentes Termos de Uso.
      </S.Paragraph>
      <S.Paragraph>
        É expressamente vedado ao Usuário alterar qualquer funcionalidade da plataforma, ou ainda,
        acessar qualquer parte com o fito de criar produto ou serviço concorrente, inclusive que
        contenha características, funções e ideias semelhantes à plataforma da{' '}
        <strong>PROFITFY</strong>. É proibida, também, a utilização de dispositivo, software ou
        outro instrumento que permita a interferência nas atividades e operações do site ou que
        tenha como objetivo o acesso indevido as informações ou do banco de dados.
      </S.Paragraph>
      <S.Paragraph>
        Reforçamos que qualquer conduta que viole as leis de propriedade intelectual bem como as
        normas aplicáveis e as expressas vedações inseridas nestes Termos de Uso sujeitará o agente
        às consequências legais, incluindo indenização por eventual dano causado.
      </S.Paragraph>
      <S.Paragraph>
        A plataforma da <strong>PROFITFY</strong> poderá expor determinados links que direcionarão
        para outros sites da internet, o que não implica que referidos sites sejam de nossa
        propriedade. Assim, a <strong>PROFITFY</strong> não se responsabiliza pelos conteúdos,
        práticas e serviços ofertados nesses outros sites, tendo em vista que não possuímos controle
        sobre tal conteúdo.
      </S.Paragraph>

      <S.Subtitle>9. Submissões de Conteúdo</S.Subtitle>
      <S.Paragraph>
        Você reconhece e concorda que quaisquer perguntas, comentários, sugestões, ideias, feedback
        ou outras informações sobre o site (“Submissões de Conteúdo”) fornecidas por você a nós não
        são confidenciais e se tornarão nossa propriedade exclusiva. Possuiremos direitos
        exclusivos, incluindo todos os direitos de propriedade intelectual, e teremos o direito de
        uso irrestrito e disseminação destas submissões de conteúdo para qualquer propósito legal,
        comercial ou outro, sem reconhecimento ou compensação para você. Você, por meio deste,
        renuncia a todos os direitos morais a qualquer um desses envios e garante que esses envios
        são originais ou que você tem o direito de enviá-los. Você concorda que não haverá recurso
        contra nós por qualquer violação alegada ou apropriação indevida de qualquer direito de
        propriedade em seus envios.
      </S.Paragraph>

      <S.Subtitle>10. Cancelamento de Conta</S.Subtitle>
      <S.Paragraph>
        Caso o Usuário pretenda cancelar sua Conta, poderá realizar automaticamente por meio do
        Dashboard da plataforma da <strong>PROFITFY</strong>. O cancelamento é instantâneo e não
        ensejará mais cobranças ao Usuário, ressalvados os valores em aberto até o efetivo
        cancelamento da conta.
      </S.Paragraph>

      <S.Subtitle>11. Da Vigência</S.Subtitle>
      <S.Paragraph>
        Os presentes Termos de Uso permanecerão vigentes desde o cadastro do usuário na plataforma
        digital, a sua respectiva utilização, perdurando enquanto a <strong>PROFITFY</strong>{' '}
        prestar o presente serviço.
      </S.Paragraph>

      <S.Subtitle>12. Política de Privacidade</S.Subtitle>
      <S.Paragraph>
        Nós reconhecemos, nos termos da{' '}
        <S.ExternalLink href="https://app.profitfy.me/privacy-policy" target="_blank  ">
          Política de Privacidade
        </S.ExternalLink>{' '}
        e da legislação aplicável, a confidencialidade e segurança das informações prestadas pelos
        Usuários, empenhando-nos em resguardar o seu sigilo, salvo por força de lei ou ordem
        judicial.
      </S.Paragraph>

      <S.Subtitle>13. Mudanças nos Termos de Uso</S.Subtitle>
      <S.Paragraph>
        O Usuário será notificado por e-mail sobre eventuais alterações nestes Termos de Uso. A
        utilização da plataforma digital seguindo estas alterações implica na aceitação do Termo de
        Uso revisado.
      </S.Paragraph>

      <S.Subtitle>14. Considerações Finais</S.Subtitle>
      <S.Paragraph>
        A nulidade total ou parcial de qualquer item destes Termos de Uso não afastará o cumprimento
        da obrigação contida nos demais itens aqui presentes. Qualquer omissão ou tolerância da
        <strong>PROFITFY</strong> em relação às disposições desses Termos e Condições não importará
        em renúncia, novação ou modificação das obrigações do Usuário.
      </S.Paragraph>
      <S.Paragraph>
        Os presentes Termos de Uso, bem como a utilização da plataforma digital e dos serviços da
        <strong>PROFITFY</strong> serão regidos pelas leis em vigência na República Federativa do
        Brasil, sendo estipulado, desde já, o foro da cidade de São Paulo/SP como o competente para
        dirimir quaisquer controvérsias ou divergências decorrentes destes Termos de Uso, utilização
        do site e serviços.
      </S.Paragraph>

      <br />
      <S.Subtitle>Estes Termos de Uso foram atualizados em 15 de março de 2023.</S.Subtitle>
    </S.Content>
  );
};

export default TermsOfUsageContent;
