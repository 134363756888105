import React from 'react';
import { SimpleCheck } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams, useHistory } from 'react-router-dom';

import { EButtonVariant } from '@domain/enums/components/EButton';
import { EHeadingSize } from '@domain/enums/components/EHeading';
import { EPlanRecurrence, EStatus } from '@domain/enums/subscription/ESubscription';
import { IPlanProps } from '@domain/interfaces/dashboard/Subscription/IPlanCard';
import { IParams } from '@domain/interfaces/IParams';

import { useStoreSubscription } from '@helpers/hooks/useStoreSubscription';
import { useSubscriptionPlans } from '@helpers/hooks/pages/dashboard/subscription/useSubscriptionPlans';
import { useDowngrade } from '@helpers/hooks/common/store/subscription/useDowngrade';
import { useToast } from '@helpers/hooks/useToast';
import { useSubscription } from '@helpers/hooks/common/store/subscription/useSubscription';
import { useSubscriptionStatus } from '@helpers/hooks/common/store/subscription/useSubscriptionStatus';

import { numberFormatter } from '@helpers/masks';
import { getRecurrence } from '@helpers/dashboard/subscription/recurrenceParser';

import { OLD_PLANS_V1, PLANS } from '@constants/plans';

import * as S from './styles';

const Plan: React.FC<IPlanProps> = ({ plan, index, selectedRecurrenceType }) => {
  const isMonthlyRecurrence = selectedRecurrenceType === EPlanRecurrence.MONTHLY;
  const isQuarterRecurrence = selectedRecurrenceType === EPlanRecurrence.QUARTER;
  const isSemiAnualRecurrence = selectedRecurrenceType === EPlanRecurrence.SEMIANUAL;

  const theme = useTheme();
  const history = useHistory();
  const { subscription } = useStoreSubscription();
  const { storeAliasId } = useParams<IParams>();
  const { verifyDowngrade, handleVerifyingDowngrade, isVerifyingDowngrade } = useDowngrade();
  const { toast } = useToast();
  const { handleSelectedPlanIdentifier, handleSelectedRecurrenceType } = useSubscription();
  const { isCancelledByCreditCardIssue } = useSubscriptionStatus();
  const {
    handleDowngradingModalOpen,
    handleUpgradingModalOpen,
    isDowngradingModalOpen,
    handlePendingPaymentModalOpen,
  } = useSubscriptionPlans();

  const planRecurrence = getRecurrence(selectedRecurrenceType);
  const isMostPopularPlan = plan.name === 'Essentials';
  const isUnlimitedPlan = plan.name === 'Unlimited';
  const isLitePlan = plan.name === 'Lite';
  const isOldPlan = OLD_PLANS_V1.find(
    oldPlan => oldPlan.identifier === subscription?.plan?.identifier,
  );
  const isDefaultPlan = PLANS.find(
    defaultPlan =>
      defaultPlan.identifier === subscription?.plan?.identifier ||
      defaultPlan.quarterIdentifier === subscription?.plan?.identifier ||
      defaultPlan.semiAnualIdentifier === subscription?.plan?.identifier,
  );
  const isCurrentPlan =
    isDefaultPlan &&
    (subscription?.plan?.identifier === plan.identifier ||
      subscription?.plan?.identifier === plan.quarterIdentifier ||
      subscription?.plan?.identifier === plan.semiAnualIdentifier) &&
    subscription?.status !== EStatus.CANCELED &&
    subscription?.recurrence === planRecurrence;
  const isCurrentSubscriptionFreeAndPlanFree =
    subscription?.plan?.identifier.includes('free') && plan.identifier.includes('free');
  const isSubscriptionStatusPaid = subscription?.status === EStatus.PAID;
  const isFreeSubscription = subscription?.plan?.identifier.includes('free');
  const isCurrentSubscriptionEnterprise = subscription?.plan?.identifier.includes('enterprise');

  const handleDowngrade = React.useCallback(async () => {
    handleVerifyingDowngrade(true);

    try {
      await verifyDowngrade(storeAliasId);

      handleVerifyingDowngrade(false);
      handleDowngradingModalOpen();
    } catch (error: any) {
      handleVerifyingDowngrade(false);
      toast.error('Ocorreu um problema para carregar os dados de downgrade! Tente novamente.');
      throw new Error(error);
    }
  }, [verifyDowngrade, storeAliasId, handleVerifyingDowngrade, toast, handleDowngradingModalOpen]);

  const onButtonClick = React.useCallback(() => {
    if (isCancelledByCreditCardIssue) {
      handlePendingPaymentModalOpen();
      return;
    }

    if (isFreeSubscription) {
      handleSelectedPlanIdentifier(plan.identifier);
      handleSelectedRecurrenceType(selectedRecurrenceType || EPlanRecurrence.MONTHLY);
      history.push(`/${storeAliasId}/subscription/address`);
      return;
    }

    const foundDefaultPlanIndex = PLANS.findIndex(
      defaultPlan => defaultPlan.identifier === subscription?.plan?.identifier,
    );

    if (isCurrentSubscriptionEnterprise && isUnlimitedPlan) {
      handleSelectedPlanIdentifier(plan.identifier);
      handleSelectedRecurrenceType(selectedRecurrenceType || EPlanRecurrence.MONTHLY);
      history.push(`/${storeAliasId}/subscription/address`);
      return;
    }

    const isUpgrading =
      isDefaultPlan &&
      !isFreeSubscription &&
      foundDefaultPlanIndex >= 0 &&
      foundDefaultPlanIndex < index;

    if (isUpgrading && subscription.status === EStatus.CANCELED) {
      handleSelectedPlanIdentifier(plan.identifier);
      handleSelectedRecurrenceType(selectedRecurrenceType || EPlanRecurrence.MONTHLY);
      history.push(`/${storeAliasId}/subscription/address`);
      return;
    }

    if (isUpgrading) {
      handleSelectedPlanIdentifier(plan.identifier);
      handleSelectedRecurrenceType(selectedRecurrenceType || EPlanRecurrence.MONTHLY);
      handleUpgradingModalOpen();
      return;
    }

    handleSelectedPlanIdentifier(plan.identifier);
    handleSelectedRecurrenceType(selectedRecurrenceType || EPlanRecurrence.MONTHLY);
    handleDowngrade();
  }, [
    handleDowngrade,
    history,
    storeAliasId,
    subscription,
    plan,
    handleUpgradingModalOpen,
    index,
    isDefaultPlan,
    handleSelectedPlanIdentifier,
    handleSelectedRecurrenceType,
    selectedRecurrenceType,
    isFreeSubscription,
    handlePendingPaymentModalOpen,
    isCancelledByCreditCardIssue,
    isCurrentSubscriptionEnterprise,
    isUnlimitedPlan,
  ]);

  const getChangePlanButtonText = React.useCallback(() => {
    if (isOldPlan) return 'Trocar Plano';

    if (isCurrentPlan) return 'Plano Atual';

    if (isCurrentSubscriptionEnterprise && plan.name === 'Unlimited') return 'Trocar Plano';

    if (isDefaultPlan && isSubscriptionStatusPaid) {
      const foundDefaultPlanIndex = PLANS.findIndex(
        defaultPlan =>
          defaultPlan.identifier === subscription?.plan?.identifier ||
          defaultPlan.quarterIdentifier === subscription?.plan?.identifier ||
          defaultPlan.semiAnualIdentifier === subscription?.plan?.identifier,
      );

      if (foundDefaultPlanIndex > index) return 'Fazer Downgrade';

      return 'Fazer Upgrade';
    }

    return 'Escolher Plano';
  }, [
    isOldPlan,
    isCurrentPlan,
    index,
    isDefaultPlan,
    subscription,
    isSubscriptionStatusPaid,
    isCurrentSubscriptionEnterprise,
    plan,
  ]);

  const getPlanPrice = React.useCallback(() => {
    if (isMonthlyRecurrence) return numberFormatter(plan.monthlyPrice, 0);

    if (isQuarterRecurrence) return numberFormatter(plan.quarterMonthlyPrice || 0, 0);

    return numberFormatter(plan.semesterMonthlyPrice || 0, 0);
  }, [isMonthlyRecurrence, isQuarterRecurrence, plan]);

  const getRecurrenceText = React.useCallback(() => {
    if (isQuarterRecurrence && !isLitePlan) return 'Cobrado a cada 3 meses';

    if (isSemiAnualRecurrence && !isLitePlan) return 'Cobrado a cada 6 meses';

    return '';
  }, [isQuarterRecurrence, isSemiAnualRecurrence, isLitePlan]);

  const getExtraOrderPrice = React.useCallback(() => {
    if (isLitePlan) return 'Sem pedidos extras';

    if (selectedRecurrenceType === EPlanRecurrence.MONTHLY)
      return `${numberFormatter(plan.extraOrderPrice, 2)} por pedido extra`;

    if (selectedRecurrenceType === EPlanRecurrence.QUARTER)
      return `${numberFormatter(plan.extraOrderPriceQuarter, 2)} por pedido extra`;

    return `${numberFormatter(plan.extraOrderPriceSemiannual, 2)} por pedido extra`;
  }, [isLitePlan, selectedRecurrenceType, plan]);

  const isRecurrence = isQuarterRecurrence || isSemiAnualRecurrence;

  return (
    <S.Wrapper>
      <S.PlanCard isMostPopularPlan={isMostPopularPlan}>
        <S.PlanTitle type={EHeadingSize.H4}>{plan.name}</S.PlanTitle>

        <S.PlanPriceWrapper>
          <S.PlanPrice>
            <S.Currency>R$</S.Currency>
            <S.Price>{getPlanPrice()}</S.Price>
          </S.PlanPrice>

          <S.PlanPriceLabel isRecurrence={isRecurrence}>por mês</S.PlanPriceLabel>
        </S.PlanPriceWrapper>

        {isRecurrence && <S.ChargeRecurrenceText>{getRecurrenceText()}</S.ChargeRecurrenceText>}

        <S.SignUpButton
          variant={EButtonVariant.PRIMARY}
          onClick={onButtonClick}
          disabled={
            (isCurrentPlan && isSubscriptionStatusPaid) || isCurrentSubscriptionFreeAndPlanFree
          }
          isLoading={isVerifyingDowngrade || isDowngradingModalOpen}
        >
          {getChangePlanButtonText()}
        </S.SignUpButton>

        <S.Benefits>
          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.ordersPerMonth === 0 ? 'Ilimitados' : plan.ordersPerMonth}</strong>{' '}
              Pedidos/mês
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>
                {plan.marketingProvider === 0 ? 'Ilimitadas' : plan.marketingProvider}
              </strong>{' '}
              {plan.marketingProvider === 1 ? 'Fonte de tráfego' : 'Fontes de tráfego'}
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.members === 0 ? 'Ilimitados' : plan.members}</strong>{' '}
              {plan.members === 1 ? 'Usuário' : 'Usuários'}
            </S.BenefitText>
          </S.Benefit>

          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>
              <strong>{plan.facebookProfile === 0 ? 'Ilimitados' : plan.facebookProfile}</strong>{' '}
              {plan.facebookProfile === 1 ? 'Perfil do Facebook' : 'Perfis do Facebook'}
            </S.BenefitText>
          </S.Benefit>

          {!isLitePlan && (
            <S.Benefit>
              <SimpleCheck size={14} color={theme.colors.green.default} />
              <S.BenefitText>Automação de mensagens</S.BenefitText>
            </S.Benefit>
          )}

          {plan.features.map((feature: string) => (
            <S.Benefit>
              <SimpleCheck size={14} color={theme.colors.green.default} />
              <S.BenefitText>{feature}</S.BenefitText>
            </S.Benefit>
          ))}
        </S.Benefits>

        {!isUnlimitedPlan && (
          <S.Tooltip>
            <S.TooltipTrigger>{getExtraOrderPrice()}</S.TooltipTrigger>

            <S.TooltipContent side="top" sideOffset={5}>
              <S.Tooltip.Arrow />

              <S.TooltipText>
                {isLitePlan ? (
                  <>
                    Caso você <strong>exceda a quantidade de pedidos</strong> contratado pelo plano,
                    você terá acesso aos pedidos até o momento em que foi excedido.
                  </>
                ) : (
                  <>
                    Caso você <strong>exceda a quantidade de pedidos</strong> contratado pelo plano,
                    será cobrado um valor <strong>adicional a cada pedido aprovado.</strong>
                  </>
                )}
              </S.TooltipText>
            </S.TooltipContent>
          </S.Tooltip>
        )}
      </S.PlanCard>
    </S.Wrapper>
  );
};

export default Plan;
