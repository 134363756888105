import React from 'react';

import { CampaignsCircle, SimpleCheck } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { useParams, useHistory } from 'react-router-dom';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { IParams } from '@domain/interfaces/IParams';

import VideoModal from '@components/common/core/DataDisplay/VideoModal';

import * as S from './styles';

const AdAccountsFeature: React.FC = () => {
  const theme = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const history = useHistory();

  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState<boolean>(false);

  const handleVideoModalOpen = React.useCallback(() => setIsVideoModalOpen(!isVideoModalOpen), [
    isVideoModalOpen,
  ]);

  const onFeatureClick = React.useCallback(() => {
    history.push(`/${storeAliasId}/dashboard/data/integrations`);
  }, [history, storeAliasId]);

  return (
    <S.Wrapper>
      <S.Header>
        <CampaignsCircle size={28} />
        <S.Title type={EHeadingSize.H5} fontWeight={EHeadingWeight.MEDIUM}>
          Contas de anúncio
        </S.Title>
      </S.Header>

      <S.Body>
        <S.Description>Verifique se suas contas de anúncio estão conectadas</S.Description>

        <S.BenefitsWrapper>
          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>Garantir dados corretos</S.BenefitText>
          </S.Benefit>
          <S.Benefit>
            <SimpleCheck size={14} color={theme.colors.green.default} />
            <S.BenefitText>Ver métricas de CPA real</S.BenefitText>
          </S.Benefit>
        </S.BenefitsWrapper>

        <S.Link
          href="https://ajuda.profitfy.me/pt-br/articles/5901827-como-integrar-com-o-facebook-ads"
          target="_blank"
        >
          Veja o tutorial
        </S.Link>
        <S.FeaturePageButton onClick={onFeatureClick}>
          Verificar contas de anúncio
        </S.FeaturePageButton>

        <VideoModal
          isOpen={isVideoModalOpen}
          toggle={handleVideoModalOpen}
          src="https://www.youtube.com/embed/vWAC8Wkt9ok?autoplay=1"
        />
      </S.Body>
    </S.Wrapper>
  );
};

export default AdAccountsFeature;
