import React from 'react';
import { X, Check } from 'phosphor-react';
import { useTheme } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';

import { useConfig } from '@helpers/hooks/useConfig';

import * as S from './styles';

const ComparePlans: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const { analytics } = useConfig();

  const onButtonClick = React.useCallback(
    planName => {
      analytics?.track('Website Test For Free Button Clicked', {
        plan_name: planName,
      });

      history.push('/sign-up/1');
    },
    [history, analytics],
  );

  return (
    <S.Wrapper>
      <S.Title>Compare nossos planos</S.Title>

      <S.TableWrapper>
        <S.TableRow>
          <S.TableData />
          <S.TableData>
            <S.PlanTitleWrapper>
              <S.PlanName>Grátis</S.PlanName>
              <S.SignUpButton onClick={() => onButtonClick('Lite')}>Comece grátis</S.SignUpButton>
            </S.PlanTitleWrapper>
          </S.TableData>
          <S.TableData>
            <S.PlanTitleWrapper>
              <S.PlanName>Starter</S.PlanName>
              <S.SignUpButton onClick={() => onButtonClick('Starter')}>
                Comece grátis
              </S.SignUpButton>
            </S.PlanTitleWrapper>
          </S.TableData>
          <S.TableData isPopularProps isFirstData>
            <S.PlanTitleWrapper>
              <S.PlanName>Essentials</S.PlanName>
              <S.SignUpButton onClick={() => onButtonClick('Essentials')}>
                Comece grátis
              </S.SignUpButton>
            </S.PlanTitleWrapper>
          </S.TableData>
          <S.TableData>
            <S.PlanTitleWrapper>
              <S.PlanName>Premium</S.PlanName>
              <S.SignUpButton onClick={() => onButtonClick('Premium')}>
                Comece grátis
              </S.SignUpButton>
            </S.PlanTitleWrapper>
          </S.TableData>
          <S.TableData>
            <S.PlanTitleWrapper>
              <S.PlanName>Unlimited</S.PlanName>
              <S.SignUpButton onClick={() => onButtonClick('Unlimited')}>
                Comece grátis
              </S.SignUpButton>
            </S.PlanTitleWrapper>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData>
            <S.FeatureName>Dashboard Inteligente</S.FeatureName>
          </S.TableData>
          <S.TableData />
          <S.TableData />
          <S.TableData isPopularProps />
          <S.TableData />
          <S.TableData />
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Pedidos pagos por mês</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>50</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>250</S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>650</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>1.250</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitado</S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Fonte de tráfego</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>1</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>2</S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>3</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitadas</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitadas</S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Usuários</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>1</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>2</S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>3</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Perfis do Facebook</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>1</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>2</S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Performance de Produto</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Exportar para Excel</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData>
            <S.FeatureName>Automações de mensagens</S.FeatureName>
          </S.TableData>
          <S.TableData />
          <S.TableData />
          <S.TableData isPopularProps />
          <S.TableData />
          <S.TableData />
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Automações</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>2</S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>4</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>10</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Mensagens por fluxos</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>2</S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>4</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>10</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitadas</S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Instâncias de Whatsapp</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>1</S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>2</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>2</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitadas</S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Envios de Whatsapp</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>Ilimitados</S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData>
            <S.FeatureName>Gerenciador de anúncios</S.FeatureName>
          </S.TableData>
          <S.TableData />
          <S.TableData />
          <S.TableData isPopularProps />
          <S.TableData />
          <S.TableData />
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Gestão de campanhas</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData>
            <S.FeatureName>Extra</S.FeatureName>
          </S.TableData>
          <S.TableData />
          <S.TableData />
          <S.TableData isPopularProps />
          <S.TableData />
          <S.TableData />
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Onboarding especial</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Assessoria dedicada</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              {/* <Check size={16} color={theme.colors.green.default} weight="bold" /> */}
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData showBorder>
            <S.DataText>Suporte prioritário</S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder isPopularProps>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <X size={16} color={theme.colors.danger.default} weight="bold" />
            </S.DataText>
          </S.TableData>
          <S.TableData showBorder>
            <S.DataText center>
              <Check size={16} color={theme.colors.green.default} weight="bold" />
            </S.DataText>
          </S.TableData>
        </S.TableRow>

        <S.TableRow>
          <S.TableData />
          <S.TableData />
          <S.TableData />
          <S.TableData isLastData isPopularProps />
          <S.TableData />
          <S.TableData />
        </S.TableRow>
      </S.TableWrapper>
    </S.Wrapper>
  );
};

export default ComparePlans;
