import React from 'react';

import { useForm } from 'react-hook-form';
import { useToast } from '@helpers/hooks/useToast';

import updateTimezoneService from '@services/pages/dashboard/adminPanel/timezone';
import Form from '@components/common/core/Inputs/Form';

import { timezones } from '@constants/timezones';
import * as S from './styles';

const Timezone: React.FC = () => {
  const { toast } = useToast();

  const { handleSubmit, register } = useForm();
  const [isChangingTimezone, setIsChangingTimezone] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async formData => {
      setIsChangingTimezone(true);

      const storeAliasId = formData.store_alias_id;
      const timezone = formData?.timezone;

      try {
        await updateTimezoneService.changeTimezone({
          storeAliasId,
          data: {
            iana_timezone: timezone,
          },
        });

        toast.success('Timezone alterado com sucesso.');
        setIsChangingTimezone(false);
      } catch (error: any) {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setIsChangingTimezone(false);
      }
    },
    [toast],
  );

  return (
    <S.ContentWrapper>
      <S.Heading>Alterar timezone da loja</S.Heading>

      <S.Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="current-store-alias-id">Alias id da loja</S.Label>
            <S.Input
              {...register('store_alias_id')}
              type="text"
              placeholder="Insira o Alias Id da loja"
              id="store-alias-id"
            />
          </S.InputGroup>
          <S.InputGroup>
            <S.Label>Timezone</S.Label>
            <S.Select {...register('timezone')}>
              {timezones.map(timezone => (
                <S.Option value={timezone} key={timezone}>
                  {timezone}
                </S.Option>
              ))}
            </S.Select>
          </S.InputGroup>

          <S.Button type="submit" isLoading={isChangingTimezone} disabled={isChangingTimezone}>
            Alterar
          </S.Button>
        </Form>
      </S.Content>
    </S.ContentWrapper>
  );
};

export default Timezone;
