import React from 'react';

import { Yampi as YampiIcon } from '@profitfy/pakkun-icons';
import { X as CloseIcon } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IYampiProps } from '@domain/interfaces/dashboard/Automations/Canvas/Integrations/yampi';
import { EYampiType } from '@domain/enums/common/integrations/providers/EYampi';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useToast } from '@helpers/hooks/useToast';
import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useAutomationsTemplate } from '@helpers/hooks/pages/dashboard/automations/useAutomationsTemplate';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { yampiSchema } from '@helpers/validators/dashboard/integrationCenter/yampi';

import yampiAuthService from '@services/pages/dashboard/integrationCenter/storeProvider/yampi/yampiAuth';

import Text from '@components/common/core/DataDisplay/Text';

import * as S from './styles';

const Yampi: React.FC<IYampiProps> = ({ toggle }) => {
  const { colors } = useTheme();
  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore } = useStoreConfig();
  const { generateInitialNodesAndEdges, templateType, templateQuery } = useAutomationsTemplate();
  const {
    selectedTriggerForMissingCredential,
    setShowSideModal,
    handleSelectWorkflowTriggerType,
  } = useAutomation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(yampiSchema) });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [providerIdValue, setProviderIdValue] = React.useState<string>('');
  const [accessTokenValue, setAccessTokenValue] = React.useState<string>('');
  const [secretKeyValue, setSecretKeyValue] = React.useState<string>('');

  const providerIdRegister = register('provider_id');
  const accessTokenRegister = register('access_token');
  const secretKeyRegister = register('secret_key');

  const onProviderIdValueChange = React.useCallback(
    event => {
      providerIdRegister.onChange(event);

      setProviderIdValue(event.target.value);
    },
    [providerIdRegister],
  );

  const onAccessTokenValueChange = React.useCallback(
    event => {
      accessTokenRegister.onChange(event);

      setAccessTokenValue(event.target.value);
    },
    [accessTokenRegister],
  );

  const onSecretKeyValueChange = React.useCallback(
    event => {
      secretKeyRegister.onChange(event);

      setSecretKeyValue(event.target.value);
    },
    [secretKeyRegister],
  );

  const onSubmit = React.useCallback(
    async data => {
      setIsSubmittingForm(true);

      if (!selectedTriggerForMissingCredential && !templateQuery) {
        toast.error(
          'Ocorreu um erro ao tentar adicionar a credencial da Yampi, tente novamente mais tarde!',
        );

        return;
      }

      try {
        const types: Array<any> = [];

        if (templateQuery) {
          types.push(templateType);
        } else {
          types.push((selectedTriggerForMissingCredential as unknown) as EYampiType);
        }

        await yampiAuthService.createYampiCredential({
          storeAliasId,
          data: {
            provider_id: data.provider_id,
            access_token: data.access_token,
            secret_key: data.secret_key,
            types,
          },
        });
        mutateStore();

        if (templateQuery) {
          generateInitialNodesAndEdges();

          setShowSideModal(false);
          handleSelectWorkflowTriggerType(templateType as ETriggers);
        }

        setIsSubmittingForm(false);
        toggle();
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [
      storeAliasId,
      toast,
      mutateStore,
      toggle,
      selectedTriggerForMissingCredential,
      handleSelectWorkflowTriggerType,
      setShowSideModal,
      templateQuery,
      templateType,
      generateInitialNodesAndEdges,
    ],
  );

  const hasAllInputsValue = providerIdValue && accessTokenValue && secretKeyValue;

  return (
    <>
      <S.Row>
        <S.GroupTitle>
          <YampiIcon size={22} />
          <S.Title>Integração com a Yampi</S.Title>
        </S.GroupTitle>
        <S.CloseButton onClick={toggle}>
          <CloseIcon size={20} color={colors.gray[3]} />
        </S.CloseButton>
      </S.Row>

      <S.Row>
        <S.Tutorial>
          Para saber como pegar essas informações da Yampi veja o{' '}
          <S.Link
            href="https://ajuda.profitfy.me/pt-br/articles/6253063-como-integra-com-a-loja-yampi"
            target="_blank"
          >
            nosso artigo
          </S.Link>{' '}
          .
        </S.Tutorial>
      </S.Row>

      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="yampi-alias">Alias</S.Label>

            <S.Input
              {...providerIdRegister}
              type="text"
              id="yampi-alias"
              placeholder="Yampi Alias"
              onChange={onProviderIdValueChange}
              isError={errors.provider_id}
              value={providerIdValue}
            />
            {errors.provider_id && <Text isErrorFeedback>{errors.provider_id.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="yampi-access-token">Access Token</S.Label>

            <S.Input
              {...accessTokenRegister}
              type="text"
              id="yampi-access-token"
              placeholder="Yampi Access Token"
              onChange={onAccessTokenValueChange}
              isError={errors.access_token}
              value={accessTokenValue}
            />
            {errors.access_token && <Text isErrorFeedback>{errors.access_token.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="yampi-secret-key">Secret Key</S.Label>

            <S.Input
              {...secretKeyRegister}
              type="text"
              id="yampi-secret-key"
              placeholder="Yampi Secret Key"
              onChange={onSecretKeyValueChange}
              isError={errors.secret_key}
              value={secretKeyValue}
            />
            {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
          </S.InputGroup>

          <S.ButtonWrapper>
            <S.ButtonCancel type="reset" onClick={toggle}>
              Cancelar
            </S.ButtonCancel>

            <S.ButtonSave
              isLoading={isSubmittingForm}
              disabled={isSubmittingForm || !hasAllInputsValue}
            >
              Salvar credencial
            </S.ButtonSave>
          </S.ButtonWrapper>
        </S.Form>
      </S.FormWrapper>
    </>
  );
};

export default Yampi;
