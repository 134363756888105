import { ApiService } from '@services/api';
import { API_DOMAIN } from '@constants/api';

interface IUpdateTimezoneProps {
  storeAliasId: string;
  data: {
    iana_timezone: string;
  };
}

export class UpdateTimezoneService {
  private readonly apiService: ApiService;

  constructor(baseURL: string) {
    this.apiService = new ApiService(baseURL);
  }

  public changeTimezone({ storeAliasId, data }: IUpdateTimezoneProps): Promise<any> {
    return this.apiService.put(`/api/v1/users/stores/${storeAliasId}/timezone`, {
      ...data,
    });
  }
}

const updateTimezoneService = new UpdateTimezoneService(API_DOMAIN);

export default updateTimezoneService;
