import React from 'react';

import { Zouti as ZoutiIcon, Copy } from '@profitfy/pakkun-icons';
import { X as CloseIcon } from 'phosphor-react';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { IParams } from '@domain/interfaces/IParams';
import { IZoutiProps } from '@domain/interfaces/dashboard/Automations/Canvas/Integrations/zouti';
import { EGateway } from '@domain/enums/common/EGateway';
import { ETriggers } from '@domain/enums/dashboard/automations/ETriggers';

import { useToast } from '@helpers/hooks/useToast';
import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import { useAutomation } from '@helpers/hooks/pages/dashboard/automation/useAutomation';
import { useAutomationsTemplate } from '@helpers/hooks/pages/dashboard/automations/useAutomationsTemplate';

import { getTranslatedIntegrationsError } from '@helpers/utils/errors/integrations/integrations';

import { zoutiSchema } from '@helpers/validators/dashboard/integrationCenter/zouti';

import zoutiService from '@services/pages/dashboard/integrationCenter/gateway/zouti';

import Text from '@components/common/core/DataDisplay/Text';

import { ETextWeight } from '@domain/enums/components/EText';
import * as S from './styles';

const Zouti: React.FC<IZoutiProps> = ({ toggle }) => {
  const theme = useTheme();

  const { storeAliasId } = useParams<IParams>();
  const { toast } = useToast();
  const { mutateStore, storeIntegrations } = useStoreConfig();
  const { generateInitialNodesAndEdges, templateType, templateQuery } = useAutomationsTemplate();
  const {
    selectedTriggerForMissingCredential,
    setShowSideModal,
    handleSelectWorkflowTriggerType,
  } = useAutomation();

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onBlur', resolver: yupResolver(zoutiSchema) });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState<boolean>(false);
  const [, setIsGettingWebhookURL] = React.useState<boolean>(false);
  const [webhookURL, setWebhookURL] = React.useState<string>('');
  const [webhookId, setWebhookId] = React.useState<string>('');
  const [isURLCopied, setIsURLCopied] = React.useState<boolean>(false);

  const secretKeyRegister = register('secret_key');
  const publicKeyRegister = register('public_key');

  const [secretKeyValue, publicKeyValue] = getValues(['secret_key', 'public_key']);

  watch();

  const hasZoutiIntegration = Boolean(
    storeIntegrations?.gateways.find(
      integration => integration.name === EGateway.ZOUTI && integration.is_active,
    ),
  );

  const generateWebhookURL = React.useCallback(async () => {
    setIsGettingWebhookURL(true);

    try {
      const { data } = await zoutiService.generateUrl({ storeAliasId });

      setWebhookURL(data?.webhook_url);
      setWebhookId(data?.webhook_id);
      setIsGettingWebhookURL(false);
    } catch (error: any) {
      setIsGettingWebhookURL(false);
      toast.error(error?.response?.data?.message);
    }
  }, [toast, storeAliasId]);

  const handleCopyURL = React.useCallback(() => {
    navigator.clipboard.writeText(webhookURL);
    setIsURLCopied(true);
    toast.success('URL copiada com sucesso.');
  }, [toast, webhookURL]);

  const onSubmit = React.useCallback(
    async formData => {
      setIsSubmittingForm(true);

      if (!selectedTriggerForMissingCredential && !templateQuery) {
        toast.error(
          'Ocorreu um erro ao tentar adicionar a credencial da Zouti, tente novamente mais tarde!',
        );

        return;
      }

      try {
        const types: Array<any> = [];

        if (templateQuery) {
          types.push(templateType);
        } else {
          types.push(selectedTriggerForMissingCredential);
        }

        if (hasZoutiIntegration) {
          const { data } = await zoutiService.getCredentialPromise({ storeAliasId });
          const { zouti_credential } = data;

          await zoutiService.updateCredential({
            storeAliasId,
            data: {
              ...formData,
              webhook_id: webhookId,
              types: [...zouti_credential.types, ...types],
            },
          });
        } else {
          await zoutiService.createCredential({
            storeAliasId,
            data: {
              ...formData,
              webhook_id: webhookId,
              types,
            },
          });
        }

        if (templateQuery) {
          generateInitialNodesAndEdges();

          setShowSideModal(false);
          handleSelectWorkflowTriggerType(templateType as ETriggers);
        }

        setIsSubmittingForm(false);
        mutateStore();
      } catch (error: any) {
        setIsSubmittingForm(false);
        toast.error(getTranslatedIntegrationsError(error));
      }
    },
    [
      toast,
      storeAliasId,
      mutateStore,
      webhookId,
      hasZoutiIntegration,
      selectedTriggerForMissingCredential,
      handleSelectWorkflowTriggerType,
      setShowSideModal,
      templateQuery,
      templateType,
      generateInitialNodesAndEdges,
    ],
  );

  React.useEffect(() => {
    generateWebhookURL();
  }, [generateWebhookURL]);

  const hasAllInputsValue = secretKeyValue && publicKeyValue;

  return (
    <>
      <S.Row>
        <S.GroupTitle>
          <ZoutiIcon size={22} />

          <S.Title>Integração com Zouti</S.Title>
        </S.GroupTitle>

        <S.CloseButton onClick={toggle}>
          <CloseIcon size={20} color={theme.colors.gray[3]} />
        </S.CloseButton>
      </S.Row>

      <S.Row>
        <S.Tutorial>1 - Copie a URL no campo abaixo.</S.Tutorial>
      </S.Row>

      <S.Row>
        <S.WebhookURLWrapper onClick={handleCopyURL} type="button">
          <S.WebhookURL weight={ETextWeight.MEDIUM}>{webhookURL}</S.WebhookURL>
          <S.IconWrapper>
            <Copy size={18} color={theme.colors.gray[1]} outline={!isURLCopied} />
          </S.IconWrapper>
        </S.WebhookURLWrapper>
      </S.Row>

      <S.Row>
        <S.Tutorial>
          Para saber como pegar as informações da Zouti veja o{' '}
          <S.Link
            href="https://ajuda.profitfy.me/pt-br/articles/8363507-como-integrar-a-zouti-pay-na-automacao-de-mensagens"
            target="_blank"
          >
            nosso artigo
          </S.Link>{' '}
          .
        </S.Tutorial>
      </S.Row>

      <S.FormWrapper>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.InputGroup>
            <S.Label htmlFor="zouti-secret-key">Chave secreta</S.Label>

            <S.TextField
              {...secretKeyRegister}
              type="text"
              placeholder="Chave secreta da Zouti"
              id="zouti-secret-key"
              isError={errors.secret_key}
            />
            {errors.secret_key && <Text isErrorFeedback>{errors.secret_key.message}</Text>}
          </S.InputGroup>

          <S.InputGroup>
            <S.Label htmlFor="zouti-public-key">Chave pública</S.Label>

            <S.TextField
              {...publicKeyRegister}
              type="text"
              placeholder="Chave pública da Zouti"
              id="zouti-public-key"
              isError={errors.public_key}
            />
            {errors.public_key && <Text isErrorFeedback>{errors.public_key.message}</Text>}
          </S.InputGroup>

          <S.ButtonWrapper>
            <S.ButtonCancel type="reset" onClick={toggle}>
              Cancelar
            </S.ButtonCancel>

            <S.ButtonSave
              type="submit"
              isLoading={isSubmittingForm}
              disabled={isSubmittingForm || !hasAllInputsValue}
            >
              Salvar credencial
            </S.ButtonSave>
          </S.ButtonWrapper>
        </S.Form>
      </S.FormWrapper>
    </>
  );
};

export default Zouti;
