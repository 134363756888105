import React from 'react';
import { Dsers as DsersIcon } from '@profitfy/pakkun-icons';
import { useTheme } from 'styled-components/macro';
import { EHeadingSize, EHeadingWeight } from '@domain/enums/components/EHeading';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EAlertVariant } from '@domain/enums/components/EAlert';
import Form from '@components/common/core/Inputs/Form';
import Text from '@components/common/core/DataDisplay/Text';
import Button from '@components/common/core/Inputs/Button';
import { useToast } from '@helpers/hooks/useToast';
import dsersAuthService from '@services/pages/dashboard/integrationCenter/erp/dsers/dsersAuth';
import { IParams } from '@domain/interfaces/IParams';
import { useConfig } from '@helpers/hooks/useConfig';
import { useHome } from '@helpers/hooks/pages/dashboard/home/useHome';

import { useStoreConfig } from '@helpers/hooks/useStoreConfig';
import * as S from './styles';

const IntegrateForm: React.FC = () => {
  const theme: any = useTheme();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { toast } = useToast();
  const { storeAliasId } = useParams<IParams>();
  const { mutateStore } = useStoreConfig();
  const { analytics, selectedStore, user } = useConfig();
  const { mutateHomeSetup } = useHome();

  const [isCreatingCredential, setIsCreatingCredential] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(
    async data => {
      setIsCreatingCredential(true);

      try {
        await dsersAuthService.createDsersCredential({
          storeAliasId,
          data: {
            email: data.email,
            password: data.password,
            sync_order_cogs: true,
            sync_order_shipping_cost_owner: true,
            sync_products: true,
            sync_products_in_sync_order_cogs: true,
            sync_remessa_conforme: false,
          },
        });

        analytics?.track(
          'Dsers Connected',
          {
            email: user?.email,
          },
          { context: { groupId: selectedStore?.alias_id } },
        );

        await mutateStore();

        mutateHomeSetup();
      } catch {
        toast.error('Ocorreu um problema ao conectar com o Dsers. Por favor, tente novamente!');
      } finally {
        setIsCreatingCredential(false);
      }
    },
    [mutateStore, toast, storeAliasId, selectedStore, analytics, user, mutateHomeSetup],
  );

  return (
    <S.ContentWrapper>
      <S.Header>
        <DsersIcon size={52} color={theme.colors.gray[2]} />
        <S.Heading type={EHeadingSize.H4} fontWeight={EHeadingWeight.MEDIUM}>
          Integração com Dsers
        </S.Heading>
      </S.Header>

      <S.FunctionalityDescriptionWrapper>
        <S.FunctionalityDescriptionHeading type={EHeadingSize.H5}>
          Descrição da funcionalidade
        </S.FunctionalityDescriptionHeading>

        <S.FunctionalityDescriptionText>
          A integração com Dsers sincroniza seus
          <strong> custos de produto e frete </strong>
          diretamente com o Aliexpress
          <strong> de forma automática.</strong>
        </S.FunctionalityDescriptionText>

        <br />

        <S.TutorialText>
          Encontrando <strong>problemas para integrar ou sincronizar</strong> com Dsers? <br />
          Temos um <strong>artigo que pode te ajudar</strong>.
          <S.ArticleLink
            href="https://ajuda.profitfy.me/pt-br/articles/6476903-como-integrar-com-o-dsers"
            target="_blank"
          >
            Acessar artigo sobre Dsers.
          </S.ArticleLink>
        </S.TutorialText>
      </S.FunctionalityDescriptionWrapper>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <S.InputGroup>
          <S.Label htmlFor="email">E-mail</S.Label>

          <S.Input {...register('email')} placeholder="E-mail" type="text" id="email" />
          {errors.email && <Text isErrorFeedback>{errors.email.message}</Text>}
        </S.InputGroup>

        <S.InputGroup>
          <S.Label htmlFor="password">Senha</S.Label>

          <S.Input {...register('password')} placeholder="Senha" type="password" id="password" />
          {errors.password && <Text isErrorFeedback>{errors.password.message}</Text>}
        </S.InputGroup>

        <Button type="submit" isLoading={isCreatingCredential} disabled={isCreatingCredential}>
          Confirmar
        </Button>
      </Form>
    </S.ContentWrapper>
  );
};

export default IntegrateForm;
